<template>
  <ValidationObserver ref="obs0" :readonly="readonly">
  <v-form @submit.prevent :readonly="readonly">
    <v-data-table
      :headers="cHeadParts"
      :items="filteredParts"
      class="elevation-0 mr-n6 mb-n4"
      tile
      :hide-default-footer="hideFooter"
      dense
      no-data-text
      :footer-props="{
        disableItemsPerPage:true,
        itemsPerPageOptions:[10]
      }"
    >
      <template v-slot:top>
        <v-row>
          <v-col
              cols="12"
              sm="6"
              md="3"
              class="mt-3  pl-6"
          >
            <v-text-field
              v-if="$can('edit', 'usedPart')"
              v-model.number="vEntry.discount"
              label="Rabat"
              dense
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
              md="3"
              class="mt-3  pl-6"
          >
            <v-checkbox
              v-if="$can('edit', 'usedPart')"
              v-model="vEntry.printValues"
              label="Drukuj ceny"
              dense
            ></v-checkbox>
          </v-col>
          <v-col
              cols="12"
              sm="6"
              md="3"
              class="mt-3  pl-6"
          >
            <v-checkbox
              v-if="$can('edit', 'usedPart')"
              v-model="vEntry.printIndex"
              label="Drukuj Index"
              dense
            ></v-checkbox>
          </v-col>
          <v-spacer></v-spacer>

          <v-dialog
            v-model="dialog"
            max-width="600px"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="readonly===false"
                class="mt-7 mr-4"
                color="primary"
                dark
                depressed
                small
                v-bind="attrs"
                v-on="on"
              >
                Dodaj część
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <ValidationProvider name="part" rules="required" v-slot="{ errors, valid }">
                      <v-text-field
                        v-model="vPart.part"
                        label="Część"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <!-- <ValidationProvider name="index" rules="required" v-slot="{ errors, valid }"> -->
                      <v-text-field
                        v-model="vPart.index"
                        label="Index"
                      ></v-text-field>
                        <!-- :success="valid" -->
                        <!-- :error-messages="errors" -->
                      <!-- </ValidationProvider> -->
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="vPart.supplier"
                        label="Dostawca"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <ValidationProvider name="quantity" rules="required|double" v-slot="{ errors, valid }">
                      <v-text-field
                        v-if="$can('edit', 'usedPart')"
                        v-model.number="vPart.quantity"
                        label="Ilość"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <ValidationProvider name="value" rules="required|double" v-slot="{ errors, valid }">
                      <v-text-field
                        v-if="$can('edit', 'usedPart')"
                        v-model.number="vPart.value"
                        label="Cena"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-if="$can('edit', 'usedPart')"
                        v-model.number="vPart.value2"
                        label="Cena dla klienta"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-checkbox
                        v-model="vPart.print"
                        label="Widoczne na wydruku"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Anuluj
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="submitParts()"
                >
                  Zapisz
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-row>
      </template>
      <template v-slot:[`item.print`]="{ item }">
        <v-simple-checkbox
          v-model="item.print"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-form>
  </ValidationObserver>
</template>

<script>
// import { autoApi } from '../services/autoApi'
// import { authenticationService } from '../services/authentication.service'

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  name: "PartsForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    // entryId: Number,
    parts: [Array, Object],
    entry: Object,
    // value: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    partsToSave: {
      type: Array,
      default: () => []
    },
    valueParts: {
      type: String,
      default: "",
    }
    // discount: Number,
    // printVal: Boolean,
  },
  data() {
    return {
      headParts: [
        { text: 'Druk', value: 'print', width: '70px' },
        { text: "Część", value: "part", sortable: false },
        { text: "Index", value: "index", sortable: false },
        { text: "Dostawca", value: "supplier", sortable: false },
        { text: "Ilość", value: "quantity", sortable: false, can: ['view', 'usedPart'] },
        { text: "Cena", value: "value", sortable: false, can: ['view', 'usedPart'] },
        { text: "Cena dla klienta", value: "value2", sortable: false, can: ['view', 'usedPart'] },
        { text: "", value: "action", sortable: false, width: '80px', can: ['add', 'usedPart'] },
      ],

      Parts: [],
      vPart: {},
      vEntry: {},
      vPartsToSave: [],

      dialog: false,
      formTitle: "Nowa część",
    }
  },

  // created: function () {
  //   console.log("parts created")
  // },

  computed: {
    hideFooter: function() {
      return this.parts.length>10?false:true
    },

    cHeadParts: function() {
      return this.headParts.filter((column) => {
        if(!column.hasOwnProperty('can')) {
          return true;
        }
        // console.log(column.can[0])
        return this.$can(column.can[0], column.can[1])
      })
    },

    filteredParts: function() {
      this.vPartsToSave // taki patent, bo inaczej nie obserwuje zmian na tej tablicy
      return this.Parts.filter((item, index) =>{
        if(Array.isArray(this.vPartsToSave)) {
          if(this.vPartsToSave.includes(-(index+1))) {
            return false
          }
        }
        return true
      });
    },

  },

  watch: {
    parts: {
      handler: function(val) {
        this.Parts = val
        this.$emit('update:valueParts', this.getValueParts());
      },
      deep: true
    },

    partsToSave: {
      handler: function(val) {
        this.vPartsToSave = val
      },
    },

    // vParts: {
    //   handler: function(val) {
    //     console.log("parts")
    //     this.$emit('update:parts', val);
    //   },
    //   deep: true
    // },

    entry: {
      handler: function(val) {
        this.vEntry = val;
      },
      deep: true
    },

    vEntry: {
      handler: function(val) {
        this.$emit('update:entry', val);
      },
      deep: true
    },
  },

  methods: {
    async submitParts() {
      // console.log('--- submitParts ---');

      if(await this.$refs.obs0.validate()) {
          let idx = this.Parts.indexOf(this.vPart);
          if(idx < 0 ) {
            idx = this.Parts.push(this.vPart) - 1
          }
          // if(typeof this.vPart.id =="undefined") {
          //   console.log(this.Parts.indexOf(this.vPart))
          //   idx = this.Parts.push(this.vPart) - 1
          // } else {
          //   idx = this.Parts.indexOf(this.vPart)
          // }

          // index+1 - bo do kasowania potrzebne wartości ujemne np -0
          if(!this.vPartsToSave.includes(idx+1)) {
            this.vPartsToSave.push(idx+1)
          }

          this.$emit('update:parts', this.Parts);
          this.$emit('update:partsToSave', this.vPartsToSave);

          this.close()
      }
    },

    deleteItem(item) {
      if(confirm('Czy na pewno chcesz usunąć ten wpis?')) {
        let idx = this.Parts.indexOf(item)
        if(!this.vPartsToSave.includes(-(idx+1))) {
          this.vPartsToSave.push(-(idx+1))
        }
        this.$emit('update:partsToSave', this.vPartsToSave);
        this.$emit('update:valueParts', this.getValueParts());
      }
    },

    editItem(item) {
      this.formTitle = "Edycja części"
      this.dialog = true;
      // this.vPart = this.parts.find(part => part.id == item.id);
      this.vPart = item;
    },

    close() {
      this.dialog = false
      // DOM not updated yet
      this.$nextTick(() => {
        // DOM updated
        this.vPart = {}
        this.formTitle = "Nowa część"
        this.$refs.obs0.reset()
        // this.editedItem = Object.assign({}, this.defaultItem)
      })
    },

    getValueParts() {
      let sum=0;
      let sum2=0;
      this.partsToSave
      if(Array.isArray(this.filteredParts) && this.$can('view', 'usedPart')) {
        this.filteredParts.forEach(item=>{
          // console.log(item)
          sum +=(item.value)*item.quantity;
          sum2 +=(item.value2)*(1-(this.vEntry.discount/100))*item.quantity;
        });
      }

      if(isNaN(sum)) sum = 0;
      if(isNaN(sum2)) sum2 = 0;

      let ret = sum>0?'na kwotę: ('+sum.toFixed(2).toString()+'zł) '+sum2.toFixed(2).toString()+'zł':'';
      // console.log(ret)
      return ret
    }

  },

};
</script>

<style>
</style>