const axios = require('axios')
import { config } from "../config";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { authenticationService } from './authentication.service'

export const autoApi = axios.create({
  baseURL: config.apiURL,
})

// to cudo sprawia, że zapytanie po relogin jest odnawiane
const refreshAuthLogic = failedRequest => autoApi.post('/api/v1/auth/relogin', {
  refreshToken: localStorage.getItem("refreshToken"),

}).then(tokenRefreshResponse => {
    localStorage.setItem('jwtToken', tokenRefreshResponse.data.access_token);
    localStorage.setItem('refreshToken', tokenRefreshResponse.data.refresh_token);
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
    return Promise.resolve();
});

createAuthRefreshInterceptor(autoApi, refreshAuthLogic);

autoApi.interceptors.response.use(
  function (response) { return response },
  function(error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && originalRequest.url === '/api/v1/auth/relogin') {
      // logout if relogin status = 403
      authenticationService.appLoggout()
      return Promise.reject(error);
    }

//     if(error.response.status === 401 && !originalRequest._retry) {

//       originalRequest._retry = true;

//       // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("jwtToken")}`;
//       // axios.post(`${config.apiURL}/api/v1/auth/relogin`, {
//       autoApi.post(`/api/v1/auth/relogin`, {
//         refreshToken: localStorage.getItem("refreshToken"),
//       }).then(response => {
//         if (response.status === 201) {
//             localStorage.setItem('jwtToken', response.data.access_token);
//             localStorage.setItem('refreshToken', response.data.refresh_token);

//             // 2) Change Authorization header
//             autoApi.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

//             // 3) return originalRequest object with Axios.
//             // originalRequest.response.config .defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
//             return autoApi(originalRequest);
//         }
//       })
//     }
//     return Promise.reject(error);
  }
);

autoApi.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// function relogin() {
//   autoApi.post(`${config.apiURL}/api/v1/auth/relogin`, {
//     refreshToken: localStorage.getItem("refreshToken"),
//   }).then(response => {
//       localStorage.setItem('jwtToken', response.data.access_token);
//       localStorage.setItem('refreshToken', response.data.refresh_token);
//       // localStorage.setItem('currentUser', JSON.stringify(response.data.user));
//   }).catch(error => {
//     console.log(error)
//     authenticationService.appLoggout();
//   })
// }