var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"readonly":_vm.readonly},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-data-table',{staticClass:"elevation-0 mr-n6 mb-n4",attrs:{"headers":_vm.cHeadJobs,"items":_vm.carJobs,"tile":"","hide-default-footer":_vm.hideFooter,"dense":"","no-data-text":"","loading":_vm.loadingJobs,"footer-props":{
      disableItemsPerPage:true,
      itemsPerPageOptions:[10]
    }},scopedSlots:_vm._u([{key:"item.jobDescription",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"info-item"},[_vm._v(_vm._s(item.jobDescription))])]}},{key:"item.user",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" ")]}},{key:"item.createDate",fn:function(ref){
    var item = ref.item;
return [(item.createDate===null)?_c('span'):_c('span',[_vm._v(_vm._s(new Date(item.createDate).toISOString().replace('T', ' ').substr(0, 19)))])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [(_vm.isOwner(item) && !_vm.readonly)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.isOwner(item) && !_vm.readonly)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-row',[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.readonly===false),expression:"readonly===false"}],staticClass:"mt-7 mr-4",attrs:{"color":"primary","dark":"","depressed":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Dodaj czynność ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('ValidationObserver',{ref:"obs0",attrs:{"readonly":_vm.readonly}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"jobDescription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var valid = ref.valid;
return [_c('v-textarea',{attrs:{"label":"Naprawa","rows":"8","error-messages":errors,"success":valid},model:{value:(_vm.vJob.jobDescription),callback:function ($$v) {_vm.$set(_vm.vJob, "jobDescription", $$v)},expression:"vJob.jobDescription"}})]}}])}),(_vm.$can('edit', 'carJob'))?_c('v-combobox',{attrs:{"items":_vm.users,"label":"Pracownik","loading":_vm.loadingUsers,"item-value":"id","item-text":"name","return-object":true},model:{value:(_vm.vJob.user),callback:function ($$v) {_vm.$set(_vm.vJob, "user", $$v)},expression:"vJob.user"}}):_vm._e(),_c('v-menu',{ref:"menuJobTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.vJob.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.vJob, "time", $event)},"update:return-value":function($event){return _vm.$set(_vm.vJob, "time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var valid = ref.valid;
return [_c('v-text-field',_vm._g({attrs:{"readonly":"","label":"Czas","error-messages":errors,"success":valid},model:{value:(_vm.vJob.time),callback:function ($$v) {_vm.$set(_vm.vJob, "time", $$v)},expression:"vJob.time"}},on))]}}],null,true)})]}}]),model:{value:(_vm.menuJobTime),callback:function ($$v) {_vm.menuJobTime=$$v},expression:"menuJobTime"}},[(_vm.menuJobTime)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menuJobTime.save(_vm.vJob.time)}},model:{value:(_vm.vJob.time),callback:function ($$v) {_vm.$set(_vm.vJob, "time", $$v)},expression:"vJob.time"}}):_vm._e()],1),_c('ValidationProvider',{attrs:{"name":"value","rules":"double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var valid = ref.valid;
return [(_vm.$can('edit', 'carJob'))?_c('v-text-field',{attrs:{"label":"Wartość","error-messages":errors,"success":valid},model:{value:(_vm.vJob.value),callback:function ($$v) {_vm.$set(_vm.vJob, "value", _vm._n($$v))},expression:"vJob.value"}}):_vm._e()]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.submitJobs(_vm.edit)}}},[_vm._v(" Zapisz ")])],1)],1)],1)],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }