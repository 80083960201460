var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{ref:"obs1"},[_c('v-form',{ref:"formEntry",attrs:{"readonly":_vm.readonly}},[_c('v-row',[(_vm.$can('view', 'entry-AcceptanceDate'))?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data przyjęcia","prepend-icon":"mdi-calendar","readonly":"","required":"","dense":"","clearable":!_vm.readonly},model:{value:(_vm.Entry.acceptanceDate),callback:function ($$v) {_vm.$set(_vm.Entry, "acceptanceDate", $$v)},expression:"Entry.acceptanceDate"}},on))]}}],null,false,2660332790),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"pl","readonly":_vm.readonly},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.Entry.acceptanceDate),callback:function ($$v) {_vm.$set(_vm.Entry, "acceptanceDate", $$v)},expression:"Entry.acceptanceDate"}})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"mileage","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Przebieg","error-messages":errors,"success":valid,"readonly":!_vm.$can('edit', 'entry-Mileage'),"dense":""},model:{value:(_vm.Entry.mileage),callback:function ($$v) {_vm.$set(_vm.Entry, "mileage", _vm._n($$v))},expression:"Entry.mileage"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"mileage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{staticClass:"denseDesc",attrs:{"rows":"8","label":"Opis usterki","error-messages":errors,"success":valid,"dense":"","readonly":!_vm.$can('edit', 'entry-Comments')},model:{value:(_vm.Entry.description),callback:function ($$v) {_vm.$set(_vm.Entry, "description", $$v)},expression:"Entry.description"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.$can('view', 'entry-PrintInfo'))?_c('v-textarea',{staticClass:"denseDesc",attrs:{"rows":"8","label":"Uwagi","dense":""},model:{value:(_vm.Entry.comments),callback:function ($$v) {_vm.$set(_vm.Entry, "comments", $$v)},expression:"Entry.comments"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.$can('view', 'entry-PrintInfo'))?_c('v-textarea',{staticClass:"denseDesc",attrs:{"rows":"8","label":"Informacja o wykonanej naprawie","dense":""},model:{value:(_vm.Entry.print_info),callback:function ($$v) {_vm.$set(_vm.Entry, "print_info", $$v)},expression:"Entry.print_info"}}):_vm._e()],1),(_vm.$can('view', 'entry-Label'))?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.labels,"item-text":"name","item-value":"id","label":"Wyróżnienie","return-object":true,"dense":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-chip',{attrs:{"color":data.item.color,"dark":""}},[_vm._v(_vm._s(data.item.name))])]}}],null,false,2632116774),model:{value:(_vm.Entry.label),callback:function ($$v) {_vm.$set(_vm.Entry, "label", $$v)},expression:"Entry.label"}})],1):_vm._e(),(_vm.$can('view', 'entry-ReleaseDate'))?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data wydania","prepend-icon":"mdi-calendar","readonly":"","dense":"","clearable":!_vm.readonly},model:{value:(_vm.Entry.releaseDate),callback:function ($$v) {_vm.$set(_vm.Entry, "releaseDate", $$v)},expression:"Entry.releaseDate"}},on))]}}],null,false,3847873789),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"pl-PL","readonly":_vm.readonly},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.Entry.releaseDate),callback:function ($$v) {_vm.$set(_vm.Entry, "releaseDate", $$v)},expression:"Entry.releaseDate"}})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }