<template>
  <div>
    <v-container >
      <ValidationObserver ref="printObs">
        <v-form ref="printForm" class="border-frame">

        <v-row class="my-2">
          <!-- <v-toolbar flat height="75" class="px-0"> -->
            <v-col cols="12" md="6">
            <ValidationProvider name="template" rules="required" v-slot="{ errors, valid }">
              <v-combobox
                v-model="template"
                :items="templates"
                :search-input.sync="searchTemp"
                label="Wybierz szablon"
                class="mb-2 mr-2"
                dense
                item-value="id"
                item-text="name"
                :loading="loadingTemplates"
                :error-messages="errors"
                :success="valid"
                ref="refTemplate"
                return-object
              ></v-combobox>
                <!-- hide-details -->
                <!-- hide-no-data -->
            </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
            <v-btn
              color="primary"
              class="mb-2 mr-2"
              @click="addTemplate"
              :disabled="addDisable"
            >
              <!-- dark -->
              <!-- v-bind="attrs"
              v-on="on" -->
              Zapisz
            </v-btn>

            <v-btn
              color="primary"
              class="mb-2 mr-2"
              @click="delTemplate"
              :disabled="delDisable"
            >
              Kasuj
            </v-btn>
          <!-- </v-toolbar> -->
          </v-col>
        </v-row>

      <!-- <v-form class="border-frame"> -->
          <v-form-base
            :model="printControls"
            :schema="printSchema"
            :col=12
          >
          </v-form-base>
        </v-form>
      </ValidationObserver>
      <!-- <v-btn text color="secondary darken-2" @click="printComponent()">Print</v-btn> -->
      <!-- </v-row> -->
    </v-container>
  </div>
</template>
<script>
import VFormBase from "vuetify-form-base"
import { autoApi } from '../services/autoApi'
// import debounce from "lodash.debounce";
import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  name: "PrintConfig",
  components: {
    VFormBase,
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    printSchema: {
      title: {
        type: "text",
        label: "Tytuł wydruku",
        // outlined: true,
        // solo: true,
        // dense: true,
        col: 6,
        class: "mr-2",
      },
      "spacer": {
        type: "spacer",
      },
      carPlate: {
        type: "checkbox",
        label: "Numer rejestracyjny",
        col: 4,
        class: "mr-2",
      },
      carCapacity: {
        type: "checkbox",
        label: "Pojemność",
        col: 4,
        class: "mr-2",
      },
      carProductionYear: {
        type: "checkbox",
        label: "Rok produkcji",
        col: 4,
        class: "mr-2",
      },
      carMark: {
        type: "checkbox",
        label: "Marka",
        col: 4,
        class: "mr-2",
      },
      carModel: {
        type: "checkbox",
        label: "Model",
        col: 4,
        class: "mr-2",
      },
      carVin: {
        type: "checkbox",
        label: "VIN",
        col: 4,
        class: "mr-2",
      },
      entryAcceptanceDate: {
        type: "checkbox",
        label: "Data przyjęcia",
        col: 4,
        class: "mr-2",
      },
      entryReleaseDate: {
        type: "checkbox",
        label: "Data wydania",
        col: 4,
        class: "mr-2",
      },
      entryMileage: {
        type: "checkbox",
        label: "Przebieg",
        col: 4,
        class: "mr-2",
      },
      contactPhone: {
        type: "checkbox",
        label: "Nr tel. zlecającego",
        col: 4,
        class: "mr-2",
      },
      entryComments: {
        type: "checkbox",
        label: "Opis usterki",
        col: 4,
        class: "mr-2",
      },
      printParts: {
        type: "checkbox",
        label: "Drukuj części",
        col: 4,
        class: "mr-2",
      },
      entryPrintInfo: {
        type: "checkbox",
        label: "Informacja o wykonanej naprawie",
        col: 4,
        class: "mr-2",
      },
    },

    printControls: {
      title: '',
      carPlate: false,
      carCapacity: false,
      carProductionYear: false,
      carMark: false,
      carModel: false,
      carVin: false,
      entryAcceptanceDate: false,
      entryMileage: false,
      entryComments: false,
      entryPrintInfo: false,
      contactPhone: false
    },
    template: '',
    templates: [],
    searchTemp: '',
    loadingTemplates: false,
    addDisable: false,
    delDisable: false,
  }),

  mounted() {
    this.getTemplates()
      .then(data => {this.templates = data})
  },

  watch: {
    template(val) {
      if(typeof val === 'object') {
        this.printControls = JSON.parse(val.template)
      }
    }
  },

  methods: {
    async addTemplate() {
      await new Promise((r) => setTimeout(r, 500)); // potrzebne żeby przepisał wartość gdy nie traci fokusa

      if(await this.$refs.printObs.validate() /*&& !!this.printControls.title*/) {
        let data = {
          template: JSON.stringify(this.printControls)
        }

        if(typeof this.template === 'object') {
          data.id = this.template.id,
          data.name = this.template.name,
          await this.saveTemplate(data, true)
        } else {
          data.name = this.template,
          await this.saveTemplate(data)
        }

        this.templates = await this.getTemplates()
      }
    },

    async saveTemplate(value, update=false) {
      let saveData;
      if(!update) {
        saveData = data => autoApi.post(`/api/v1/print-templates`,data);
      } else {
        saveData = data => autoApi.put(`/api/v1/print-templates/${value.id}`, data);
      }

      return new Promise((resolve, reject) => {
        saveData(value)
          .then(response => resolve(response))
          .catch(err => reject(err));
      })
    },

    async getTemplates(val) {
      this.loadingTemplates = true;
      // console.log(val);
      let ret = await autoApi.get(`/api/v1/print-templates`, {
        params: {
          search: val,
          // order: sort,
          skip: 0,
          take: 15
        }
      });

      this.loadingTemplates = false;
      return ret.data;
    },

    delTemplate() {
      // console.log(Object.assign({}, item));
      if(confirm('Czy na pewno chcesz usunąć ten szablon?')) {
        autoApi
          .delete(`/api/v1/print-templates/${this.template.id}`)
          .then(()=>{
            this.template = null
            this.getTemplates()
              .then(data => {this.templates = data})
          })
          .catch(err=>{
            console.log(err);
            // authenticationService.authError(err);
          })
      }
    },

  }

}
</script>
