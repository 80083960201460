var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('Entry',{attrs:{"show":_vm.dialog,"entryId":_vm.entryId,"reload":_vm.reload,"new-form":_vm.newForm},on:{"update:show":function($event){_vm.dialog=$event},"update:reload":function($event){_vm.reload=$event}}}),_vm._v(" Serwis "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.$can('add', 'entry'))?_c('v-btn',{staticClass:"ml-6",attrs:{"color":"accent","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.newItem()}}},[_c('v-icon',[_vm._v("mdi-plus-thick")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.results,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalItems,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.loading,"items-per-page":20,"footer-props":{
      disableItemsPerPage:true,
      itemsPerPageOptions:[20]
    },"item-class":_vm.rowBackground,"dense":""},on:{"update:options":function($event){_vm.options=$event},"dblclick:row":_vm.dblClickRow},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cropText(item.description, 50))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(item.description))])])]}},(_vm.$can('view', 'contact'))?{key:"item.car.plate",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.car.plate[0]==='_')?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(item.car.plate))])])]}}],null,true)},[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v("tel. "+_vm._s(item.car.contact.phone?item.car.contact.phone:'____'))])])]}}:null,(_vm.$can('view', 'contact'))?{key:"item.car.vin",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.car.vin==='')?_c('span',{staticStyle:{"display":"inline-block","width":"100%"}}):_c('span',[_vm._v(_vm._s(item.car.vin))])])]}}],null,true)},[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(_vm.getCarInfo(item)))])])]}}:null,{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","dark":_vm.rowTextColor(item)},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),(_vm.$can('delete', 'entry'))?_c('v-icon',{attrs:{"small":"","dark":_vm.rowTextColor(item)},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }