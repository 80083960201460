<template>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" align="center">

          <v-btn class="mx-2" fab dark large color="cyan">
            <v-icon >mdi-current-ac</v-icon>
          </v-btn>
          &nbsp;
          <v-btn class="mx-2" fab dark large color="pink">
            <v-icon x-large>mdi-engine-outline</v-icon>
          </v-btn>
          &nbsp;
          <v-btn class="mx-2" fab dark large color="blue">
          <v-icon x-large>mdi-chip</v-icon>
          </v-btn>
          <br/>
          <br/>
          <span style="color: #494949" class="display-3 font-weight-bold font-italic">
          Serwis Autoelektroniki
          <br/>
          <!-- <v-icon x-large>mdi-car-door-lock</v-icon>&nbsp;
          <v-icon x-large>mdi-car-esp</v-icon>&nbsp;
          <v-icon x-large>mdi-car-battery</v-icon> -->
          </span>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
// @ is an alias to /src
// import Entries from '@/components/Entries.vue'

export default {
  name: 'home',
  // components: {
  //   Entries
  // }
}
</script>
