// const host = window.location.hostname;
//     $axios.setBaseURL("http://" + host + ":8080");

/*
* VUE_APP_ musi być w nazwie parametru
*/
let port = process.env.VUE_APP_API_PORT?":"+process.env.VUE_APP_API_PORT:"";
let location = window.location.protocol + "//" + window.location.hostname + port;

export const config = {
  apiURL: location
  // apiURL: process.env.VUE_APP_API_URL || location //'http://192.168.89.5:3002'
  // apiURL: 'http://auto-backend-c0a8783c.nip.io'
}
 