import { defineAbility } from '@casl/ability'
import { authenticationService } from '../services/authentication.service'

/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
// function subjectName(item) {
//   if (!item || typeof item === 'string') {
//     return item
//   }

//   return item.__type
// }

export default defineAbility(
  can => {
    if(authenticationService.hasRole('biuro')) {
      can(['view','add','edit'], 'usedPart')
      can(['view'], 'carJob')
      can(['add', 'edit'], 'mark')
      can(['add', 'edit'], 'plate')
      can(['add', 'edit'], 'capacity')
      can(['add', 'edit'], 'productionYear')
      can(['add', 'edit'], 'vin')
      can(['add', 'view', 'edit'], 'contact')
      can(['add', 'delete', 'print'], 'entry')
      can(['add', 'view', 'edit'], 'entry-AcceptanceDate')
      can(['add', 'view', 'edit'], 'entry-PrintInfo')
      can(['add', 'view', 'edit'], 'entry-Comments')
      can(['add', 'view', 'edit'], 'entry-Mileage')
      can(['add', 'view', 'edit'], 'entry-Label')
      can(['add', 'view', 'edit'], 'entry-ReleaseDate')
      can(['add', 'view', 'edit'], 'parts')
      can(['view'], 'menuTerminarz')
      can(['view'], 'menuAdmin')
      can(['view'], 'menuPrintConfig')
      // can(['update', 'delete'], 'Todo')
    }

    if(authenticationService.hasRole('admin')) {
      can(['add', 'edit', 'view'], 'usedPart')
      can(['add', 'edit', 'view'], 'carJob')
      can(['add', 'edit'], 'mark')
      can(['add', 'edit'], 'plate')
      can(['add', 'edit'], 'capacity')
      can(['add', 'edit'], 'productionYear')
      can(['add', 'edit'], 'vin')
      can(['add', 'view', 'edit'], 'contact')
      can(['add', 'delete', 'print'], 'entry')
      can(['add', 'view', 'edit'], 'entry-AcceptanceDate')
      can(['add', 'view', 'edit'], 'entry-PrintInfo')
      can(['add', 'view', 'edit'], 'entry-Comments')
      can(['add', 'view', 'edit'], 'entry-Mileage')
      can(['add', 'view', 'edit'], 'entry-Label')
      can(['add', 'view', 'edit'], 'entry-ReleaseDate')
      can(['add', 'view', 'edit'], 'parts')
      can(['view'], 'menuTerminarz')
      can(['view'], 'menuAdmin')
      can(['view'], 'menuPrintConfig')
      can(['view'], 'menuUsers')
      can(['view'], 'menuReport')
    }

    if(authenticationService.hasRole('serwis')) {
      can(['add'], 'usedPart')
      can(['add'], 'carJob')
      can(['view'], 'entry-Comments')
      can(['add', 'view', 'edit'], 'entry-Mileage')

      // can(['update', 'delete'], 'Todo')
    }
  }

)