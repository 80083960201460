export default {
  methods: {
    isEmptyObject(obj) {
      if(obj == null || Object.keys(obj).length === 0) {
        return true
      }
      return false
    },

    pureObject(obj) {
      if(obj !=null) {
        return JSON.parse(JSON.stringify(obj))
      }
      return {}
    },

    isEmptyPureObject(obj) {
      return this.isEmptyObject(this.pureObject(obj))
    }


  }
};