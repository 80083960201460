<template>
  <div>
    <v-toolbar flat color="white">
      <Entry :show.sync="dialog" :entryId="entryId" :reload.sync="reload" :new-form="newForm" />
      Serwis
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Szukaj"
        single-line
        hide-details
        clearable
      ></v-text-field>
      <v-btn v-if="$can('add', 'entry')" color="accent" dark fab small @click="newItem()" class="ml-6">
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="results"
      :search="search"
      :options.sync="options"
      :server-items-length="totalItems"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :loading="loading"
      :items-per-page="20"
      :footer-props="{
        disableItemsPerPage:true,
        itemsPerPageOptions:[20]
      }"
      class="elevation-1"
      :item-class="rowBackground"
      dense
      @dblclick:row="dblClickRow"
    >

      <!-- <template v-slot:item>

      </template> -->

      <!-- <template v-slot:[`item.car.plate`]="{ item }">
        <span v-if="item.car.plate[0]==='_'" > - </span>
        <span v-else>{{ item.car.plate }}</span>
      </template> -->

      <template v-slot:[`item.description`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ cropText(item.description, 50) }}
            </span>
          </template>
          <span style="white-space: pre-line;">{{ item.description }}</span>
        </v-tooltip>
      </template>

      <template  v-if="$can('view', 'contact')" v-slot:[`item.car.plate`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span v-if="item.car.plate[0]==='_'" > - </span>
              <span v-else>{{ item.car.plate }}</span>
            </span>
          </template>
          <span style="white-space: pre-line;">tel. {{ item.car.contact.phone?item.car.contact.phone:'____' }}</span>
        </v-tooltip>
      </template>

      <template  v-if="$can('view', 'contact')" v-slot:[`item.car.vin`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span style="display: inline-block; width: 100%;" v-if="item.car.vin===''" ></span>
              <span v-else>{{ item.car.vin }}</span>
            </span>
          </template>
          <span style="white-space: pre-line;">{{ getCarInfo(item) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon small :dark="rowTextColor(item)" class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon v-if="$can('delete', 'entry')" small :dark="rowTextColor(item)" @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import { server } from "../helper";
// import axios from "axios";
import debounce from "lodash.debounce";
import Entry from "@/components/Entry";
import { authenticationService } from '../services/authentication.service'
import { autoApi } from '../services/autoApi'
// import Axios from 'axios';

export default {
  name: "Entries",
  components: {
    Entry
  },
  data() {
    return {
      sortBy: "acceptanceDate",
      sortDesc: false,
      reload: false,
      dialog: false,
      entryId: null,
      search: "",
      loading: true,
      searching: true,
      options: {},
      headers: [
        { text: "Nr zlecenia", value: "id", align: "left", sortable: false },
        { text: "Nr rejestracyjny", value: "car.plate" },
        { text: "VIN", value: "car.vin" },
        { text: "Marka", value: "car.mark.name" },
        { text: "Model", value: "car.model.name" },
        // { text: "Pojemność", value: "car.capacity" },
        // { text: "Silnik", value: "car.engine" },
        // { text: "Rocznik", value: "car.productionYear" },
        // { text: "Kontakt", value: "contact" },
        // { text: "Email", value: "email" },
        { text: "Przyjęcie", value: "acceptanceDate", width: '110px' },
        { text: "Wydanie", value: "releaseDate", width: '110px' },
        // { text: "Przebieg", value: "mileage" },
        // { text: "Opis", value: "description" },
        { text: "Opis usterki", value: "description"},
        { text: "", value: "action", sortable: false, width: '100px' },
      ],
      logged: false,
      results: [],
      totalItems: 0,
      newForm: true,
    };
  },

  mounted() {
    authenticationService.currentUser.subscribe(this.setLogged)
    autoApi.logged // ?????????????????????
  },

  watch: {
    options: {
      handler() {
        // taki myk ustawia domyślne sortowanie
        if(this.options.sortBy.length == 0) {
          this.options.sortBy.push("acceptanceDate")
          this.options.sortDesc.push(false)
        }
        this.getDataFromApi();
      },
      deep: false
    },

    search: debounce(function() {
      this.getDataFromApi();
    }, 500),

    reload(val) { //reload jest potrzebny w przypadku dodania nowego zgłoszenia
      if(val) {
        this.getDataFromApi();
        this.reload = false;
      }
    },

    logged() {
      // to chyba raczej nie potrzebne bo i tak co inngo wymusza czytanie
      // this.reload = true;
    }


  },

  methods: {
    // tu zrobić porządki bo trochę nie ładna ta funkcja
    async getDataFromApi() {
      // let items, total, error
      if(this.logged) {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        // console.log(this.search);

        let offset = 0;
        if (itemsPerPage > 0) {
          offset = (page - 1) * itemsPerPage;
        }

        let sort;
        if (sortBy.length === 1 && sortDesc.length === 1) {
          sort = {
            ['id']: sortDesc[0] ? "ASC" : "DESC", //potrzebne do sortowania, bo acceptanceDate nie ma minut i sortuje z tego samego dnia odwrotnie
            [sortBy[0]]: sortDesc[0] ? "ASC" : "DESC",
          };
        }

        let getAllEntries = this.search!== 'undefined'?false:true;

        autoApi.get('/api/v1/entries', {
          params: {
            search: this.search,
            order: sort,
            skip: offset,
            take: itemsPerPage,
            all: getAllEntries
          }
        }).then(response => {
          this.results = response.data;
          this.loading = false;
        })

        autoApi.get(`/api/v1/entries/count`, {
          params: {
            search: this.search,
            order: sort,
            skip: offset,
            take: itemsPerPage,
            all: getAllEntries
          }
        }).then(response => {
          this.totalItems = response.data;
        })
      }
    },

    getColor (item) {
      return item.label?item.label.color:'white'
    },

    rowTextColor(item) {
      if(item.label)
        if(item.label.color.indexOf('lighten') >= 0){
        return false;
      } else {
        return true;
      }
      return false;
    },

    rowBackground(item) {
      let ret = item.label?item.label.color:'';
      ret += this.rowTextColor(item)?' white--text':'';
      return ret;
    },

    dblClickRow(event, data) {
      if(data) {
        this.editItem(data.item)
      }
    },

    editItem(item) {
      // this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      this.entryId = item.id;
      // console.log(Object.assign({}, item));
      this.dialog = true;
      this.newForm = false;
    },

    deleteItem(item) {
      // console.log(Object.assign({}, item));
      if(confirm('Czy na pewno chcesz usunąć ten wpis?')) {
        console.log(item.id)
        autoApi
          .delete(`/api/v1/entries/${item.id}`)
          .then(()=>{
            this.reload=true;
          })
          .catch(err=>{
            console.log(err);
            authenticationService.authError(err);
          })
      }
    },

    newItem() {
      this.entryId = 0;
      this.dialog = true;
      this.newForm = true;
    },

    setLogged(val) {
      this.logged = val!==null?true:false
    },

    cropText(text, len=100) {
      if(text.length>len) {
        return text.slice(0, len-3).concat('...')
      }
      return text;
    },

    getCarInfo(item) {
      let ret = 'Rok: ' + item.car.productionYear + '\n';
      ret += 'Pojemność: ' + item.car.capacity + '\n';
      ret += item.mileage ? 'Przebieg: ' + item.mileage : '';
      return ret;
    }
  }
};
</script>

<style>
.class-on-data-table table {
  table-layout: fixed;
}
</style>