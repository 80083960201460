var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs0",attrs:{"readonly":_vm.readonly}},[_c('v-form',{attrs:{"readonly":_vm.readonly},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-data-table',{staticClass:"elevation-0 mr-n6 mb-n4",attrs:{"headers":_vm.cHeadParts,"items":_vm.filteredParts,"tile":"","hide-default-footer":_vm.hideFooter,"dense":"","no-data-text":"","footer-props":{
      disableItemsPerPage:true,
      itemsPerPageOptions:[10]
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"mt-3  pl-6",attrs:{"cols":"12","sm":"6","md":"3"}},[(_vm.$can('edit', 'usedPart'))?_c('v-text-field',{attrs:{"label":"Rabat","dense":""},model:{value:(_vm.vEntry.discount),callback:function ($$v) {_vm.$set(_vm.vEntry, "discount", _vm._n($$v))},expression:"vEntry.discount"}}):_vm._e()],1),_c('v-col',{staticClass:"mt-3  pl-6",attrs:{"cols":"12","sm":"6","md":"3"}},[(_vm.$can('edit', 'usedPart'))?_c('v-checkbox',{attrs:{"label":"Drukuj ceny","dense":""},model:{value:(_vm.vEntry.printValues),callback:function ($$v) {_vm.$set(_vm.vEntry, "printValues", $$v)},expression:"vEntry.printValues"}}):_vm._e()],1),_c('v-col',{staticClass:"mt-3  pl-6",attrs:{"cols":"12","sm":"6","md":"3"}},[(_vm.$can('edit', 'usedPart'))?_c('v-checkbox',{attrs:{"label":"Drukuj Index","dense":""},model:{value:(_vm.vEntry.printIndex),callback:function ($$v) {_vm.$set(_vm.vEntry, "printIndex", $$v)},expression:"vEntry.printIndex"}}):_vm._e()],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.readonly===false),expression:"readonly===false"}],staticClass:"mt-7 mr-4",attrs:{"color":"primary","dark":"","depressed":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Dodaj część ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"part","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Część","error-messages":errors,"success":valid},model:{value:(_vm.vPart.part),callback:function ($$v) {_vm.$set(_vm.vPart, "part", $$v)},expression:"vPart.part"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Index"},model:{value:(_vm.vPart.index),callback:function ($$v) {_vm.$set(_vm.vPart, "index", $$v)},expression:"vPart.index"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Dostawca"},model:{value:(_vm.vPart.supplier),callback:function ($$v) {_vm.$set(_vm.vPart, "supplier", $$v)},expression:"vPart.supplier"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"quantity","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var valid = ref.valid;
return [(_vm.$can('edit', 'usedPart'))?_c('v-text-field',{attrs:{"label":"Ilość","error-messages":errors,"success":valid},model:{value:(_vm.vPart.quantity),callback:function ($$v) {_vm.$set(_vm.vPart, "quantity", _vm._n($$v))},expression:"vPart.quantity"}}):_vm._e()]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"value","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var valid = ref.valid;
return [(_vm.$can('edit', 'usedPart'))?_c('v-text-field',{attrs:{"label":"Cena","error-messages":errors,"success":valid},model:{value:(_vm.vPart.value),callback:function ($$v) {_vm.$set(_vm.vPart, "value", _vm._n($$v))},expression:"vPart.value"}}):_vm._e()]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.$can('edit', 'usedPart'))?_c('v-text-field',{attrs:{"label":"Cena dla klienta"},model:{value:(_vm.vPart.value2),callback:function ($$v) {_vm.$set(_vm.vPart, "value2", _vm._n($$v))},expression:"vPart.value2"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-checkbox',{attrs:{"label":"Widoczne na wydruku"},model:{value:(_vm.vPart.print),callback:function ($$v) {_vm.$set(_vm.vPart, "print", $$v)},expression:"vPart.print"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.submitParts()}}},[_vm._v(" Zapisz ")])],1)],1)],1)],1)]},proxy:true},{key:"item.print",fn:function(ref){
    var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.print),callback:function ($$v) {_vm.$set(item, "print", $$v)},expression:"item.print"}})]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }