// import debounce from 'lodash.debounce';
import Vue from 'vue'
import VueI18n from 'vue'
import App from './App.vue'
import "./vee-validate";
import router from './router'
import vuetify from './plugins/vuetify';

import axios from "axios";
import qs from "qs";

import ability from './config/ability'
import { abilitiesPlugin } from '@casl/vue'
import VueHtmlToPaper from 'vue-html-to-paper';

//it's required for advanced queries
axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

const options = {
  name: '_blank',
  specs: ['toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=4000,height=4000'],
  /*[
    'fullscreen=yes',
    'titlebar=no',
    'scrollbars=yes'
  ],*/
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    '/css/print.css'
  ],
  // autoClose: false,
  // timeout: 1000, // default timeout before the print window appears
  // windowTitle: "ala ma asa"//window.document.title, // override the window title
}

Vue.config.productionTip = false

Vue.use(abilitiesPlugin, ability)
Vue.use(VueHtmlToPaper, options);

const i18n = new VueI18n({
  locale: 'pl', // set locale
})

new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
