<template>
  <div>
    <div id="printInner" style="display: none">
      <div class="headcontainer">
        <img src="logo-autoelektronika.svg" style="margin-top: 16px; margin-bottom: 5px;"  height="105" width="588" />
        <div>
          <div class="contact-item">
          ul. Mostowa 3, Pokrówka<br>
          22-100 Chełm<br>
          NIP 563-142-56-80
          </div>
          <div class="contact-item">
          tel./fax 82 563 79 33<br>
          tel.kom. 660 27 11 60<br>
          serwis@sapiaszko.pl<br>
          www.sapiaszko.pl
          </div>
        </div>
      </div>
      <hr>
      <h4 class="short-item" v-if="printControls.title"> {{printControls.title}}</h4>

      <div class="info-label" v-if="printControls.entryComments">Dane pojazdu:</div>
      <table>
        <tr v-if="printControls.carPlate">
          <td>Nr rejestracyjny:</td>
          <td>{{car.plate}}</td>
        </tr>
        <tr v-if="printControls.carMark">
          <td>Marka:</td>
          <td>{{!!car.mark?car.mark.name:''}}</td>
        </tr>
        <tr v-if="printControls.carModel">
          <td>Model:</td>
          <td>{{!!car.model?car.model.name:''}}</td>
        </tr>
        <tr v-if="printControls.carProductionYear && !!car.productionYear">
          <td>Rok produkcji:</td>
          <td>{{car.productionYear}}</td>
        </tr>
        <tr v-if="printControls.carCapacity && !!car.capacity">
          <td>Pojemność:</td>
          <td>{{car.capacity}}</td>
        </tr>
        <tr v-if="printControls.entryMileage">
          <td>Przebieg:</td>
          <td>{{entry.mileage}}</td>
        </tr>
        <tr v-if="printControls.carVin && !!car.vin">
          <td>VIN:</td>
          <td>{{car.vin}}</td>
        </tr>
        <tr v-if="printControls.contactPhone && !!contact.phone">
          <td>Nr tel. zlecającego:</td>
          <td>{{contact.phone}}</td>
        </tr>

        <tr v-if="printControls.entryAcceptanceDate">
          <td>Data przyjęcia:</td>
          <td>{{entry.acceptanceDate}}</td>
        </tr>
        <tr v-if="printControls.entryReleaseDate">
          <td>Data wydania:</td>
          <td>{{entry.releaseDate}}</td>
        </tr>
      </table>

      <br>
      <div class="info-label" v-if="printControls.entryComments">Opis usterki:</div>
      <div  class="info-item">{{entry.description}}</div>
      <br><br>

      <div class="info-label" v-if="printControls.entryPrintInfo">Informacja o wykonanej naprawie</div>
      <div  class="info-item">{{ entry.print_info }}</div>

      <br>

      <div v-if="printControls.printParts">
        <div class="info-label">Lista użytych części</div>
        <table>
          <tr v-for="item in printParts" :key="item.id">
            <td>{{item.part}}</td>
            <td v-if="entry.printIndex">{{item.index}}</td>
            <td v-if="entry.printValues==true">{{item.quantity * item.value2}} zł</td>
          </tr>
        </table>
      </div>

      <br><br>

      <div class="headcontainer">
        <div class="sign">
        <hr>
        Podpis wydającego
        </div>
        <div class="sign">
        <hr>
        Podpis klienta
        </div>
      </div>
    </div>
    <v-container class="justify-center">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" align="center">
            <!-- :search-input.sync="searchTemp" -->
          <v-combobox
            v-model="template"
            :items="templates"
            label="Wybierz szablon"
            class="my-6"
            dense
            item-value="id"
            item-text="name"
            :loading="loadingTemplates"
            ref="refTemplate"
            return-object
          ></v-combobox>
    <!-- <v-btn text color="secondary darken-2" @click="printComponent()">Print</v-btn> -->
        </v-col>
      </v-row>
  </v-container>
  </div>
</template>
<script>
import nl2br from "@/mixins/nl2br"
import { autoApi } from '../services/autoApi'

export default {
  name: "Print",
  components: {
  },

  props: {
    print: Boolean,
    car: Object,
    entry: Object,
    contact: Object,
    parts: Array,
  },

  data: () => ({
    // vEntry: {},
    printControls: {
      title: '',
      carPlate: false,
      carCapacity: false,
      carProductionYear: false,
      carMark: false,
      carModel: false,
      carVin: false,
      entryAcceptanceDate: false,
      entryMileage: false,
      entryComments: false,
      entryPrintInfo: false,
      contactPhone: false,
    },

    loadingTemplates: false,
    template: null,
    templates: [],

  }),

  mounted() {
    this.getTemplates()
      .then(data => {this.templates = data})
  },

  mixins: [nl2br],

  computed: {
    printParts: function() {
      return this.parts.filter((part) => {
        return part.print
      })
    },
  },

  watch: {
    template(val) {
      if(typeof val === 'object') {
        this.printControls = JSON.parse(val.template)
      }
    },

    async print(val) {
      if(!!this.template && val) {
        await this.printComponent()
      }
      this.$emit('update:print', false);
    }
  },

  methods: {
    async printComponent () {
      await this.$htmlToPaper('printInner');
    },

    async getTemplates(val) {
      this.loadingTemplates = true;
      // console.log(val);
      let ret = await autoApi.get(`/api/v1/print-templates`, {
        params: {
          search: val,
          // order: sort,
          skip: 0,
          take: 15
        }
      });

      this.loadingTemplates = false;
      return ret.data;
    },

  },
}
</script>
