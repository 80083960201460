<template>
  <div>
    <v-toolbar max-width="650" class="mx-auto" flat color="white">
      <v-spacer></v-spacer>
      <v-btn color="accent" dark fab small @click="newItem()" class="ml-6">
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn>
    </v-toolbar>
    <v-dialog v-model="edit" max-width="600" persistent class="my-1" scrollable>
      <v-card>
        <v-card-text>
            <!-- v-model="formValid" -->
          <v-form
            ref="userForm"
          >
            <!-- lazy-validation -->

            <v-form-base
              :model="user"
              :schema="userSchema"
              :col=12
              @click="clickForm"
            >

            </v-form-base>
            <v-progress-linear
              :color="score.color"
              :value="score.value"
            ></v-progress-linear>

          </v-form>

        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
        <!-- zwracanie wartości -->
        <v-btn text color="secondary darken-2" @click="edit=false">
          <span>Zamknij</span>
          <!-- <v-icon>mdi-close-circle</v-icon> -->
        </v-btn>
        <v-btn text color="secondary darken-2" @click="saveUser();">
          <span>Zapisz</span>
          <!-- <v-icon>mdi-content-save</v-icon> -->
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="px-3 mx-auto" max-width="550" elevation="0">
    <v-data-table
      :headers="headers"
      :items="users"
      :loading="loading"
      dense
      :items-per-page="20"
      :footer-props="{
        disableItemsPerPage:true,
        itemsPerPageOptions:[20]
      }"
    >
      <template v-slot:[`item.lock`]="{ item }">
        <v-icon v-if="item.lock===true" color="red" >mdi-account-cancel</v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>

    </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { autoApi } from '../services/autoApi'
import VFormBase from "vuetify-form-base"
import zxcvbn from 'zxcvbn'

const rules = {
  required: value => !!value || 'Pole wymagane',
  min: v => (v && v.length >= 8) || 'Minimum 8 liter',
  strength: v => (v && zxcvbn(v).score >= 3) || 'Słabe hasło. Użyj kombinacji liter, cyfr, i symboli.',
}

export default {
  name: "Users",

  components: {
    VFormBase
  },

  data() {
    return {
      users: [],
      headers: [
        { text: "Imie", value: "firstName" },
        { text: "Nazwisko", value: "lastName" },
        { text: "Login", value: "username" },
        { text: "Rola", value: "roles[0].role" },
        { text: "", value: "lock" },
        { text: "", value: "action", sortable: false, width: '100px' },
      ],
      loading: false,
      edit: false,
      user: {},
      userSchema: {
        firstName: {
          type: "text",
          label: "Imię",
          col: 6,
          class: "mr-2",
          rules: [rules.required],
          validateOnBlur: true,
        },
        lastName: {
          type: "text",
          label: "Nazwisko",
          col: 6,
          class: "mr-2",
          rules: [rules.required],
          validateOnBlur: true,
        },
        username: {
          type: "text",
          label: "Login",
          col: 6,
          class: "mr-2",
          rules: [rules.required],
          validateOnBlur: true,
        },
        role: {
          type: "combobox",
          label: "Rola",
          col: 6,
          class: "mr-2",
          items: ['guest', 'serwis', 'biuro', 'admin'],
          rules: [rules.required],
          validateOnBlur: true,
        },
        password: {
          type: "password",
          clearable: false,
          label: "Hasło",
          col: 6,
          class: "mr-2",
          validateOnBlur: true,
          ref: "userPassword", // ????
          appendIcon: 'mdi-eye'
        },
        lock: {
          type: 'checkbox',
          label: 'Konto zablokowane',
          col: 5,
          color: "red"
        },
      },
      newForm: false,
    }
  },

  computed: {
    score: function() {
      if(this.user.password != null) {
        const result = zxcvbn(this.user.password);

        switch (result.score) {
          case 4:
            return {
              color: "light-blue",
              value: 100
            };
          case 3:
            return {
              color: "light-green",
              value: 75
            };
          case 2:
            return {
              color: "yellow",
              value: 50
            };
          case 1:
            return {
              color: "orange",
              value: 25
            };
          default:
            return {
              color: "red",
              value: 0
            };
        }
      } else {
        return {}
      }
    },

    // cUserSchema() {
    //   if(this.newForm) {
    //     let ret = this.userSchema
    //     ret.password.rules = [rules.required, rules.min, rules.strength]
    //     return ret
    //   } else {
    //     return this.userSchema;
    //   }
    // },

  },

  mounted() {
    this.getUsers();
  },

  methods: {
    async getUsers() {
      // let items, total, error
      this.loading = true;

      autoApi.get('/api/v1/users')
        .then(response => {
          this.users = response.data;
          this.loading = false;
        })

    },

    async getUser(id) {
      const ret = await autoApi.get(`/api/v1/users/${id}`);
        // .then(response => {
        //   this.users = response.data;
        //   console.log(this.users)
        //   this.loading = false;
        // })
      // ret.password = ''
      return ret.data;
    },

    newItem() {
      this.user = {}
      this.newForm = true;
      this.initUserSchema()
      this.resetForm()
      this.edit = true
    },

    async editItem(item) {
      let user = await this.getUser(item.id)
      if(user) {
        user.password = ""
        this.user = user;

        if(this.user.roles.length > 0) {
          this.user.role = this.user.roles[0].role // to trochę słabe wogóle czy potrzebna jest tablica
        }
      }
      // console.log(this.user)
      this.newForm = false;
      this.initUserSchema()
      this.resetForm()
      this.edit = true;
      // console.log(this.$refs.userForm)
    },

    async deleteItem(item) {
      if(confirm('Czy na pewno chcesz usunąć ten wpis?')) {
        console.log(item.id)
        autoApi
          .delete(`/api/v1/users/${item.id}`)
          .then(()=>{
            this.getUsers()
          })
          .catch(err=>{
            console.error(err);
            // authenticationService.authError(err);
          })
      }
    },

    async saveUser() {

      // let test = (newItem) => {
      //   if(newItem) {
      //     return this.$refs.userForm.validate()
      //   } else {
      //     // find uid of password input
      //     let passInput = this.$refs.userForm.inputs.find(item => {
      //       return item.type === 'password'
      //     })
      //     console.log(passInput.rules)
      //     // passInput.rules = []

      //     Object.entries(this.$refs.userForm.errorBag).every((key) => {
      //       return (key[1] && key[0] !== passInput._uid)?true:false
      //       // console.log(`${key[1]} | ${key[0]} | ${passUid} | ${ret}`)
      //     })
      //     return true
      //   }
      // }

      if(this.$refs.userForm.validate()/*test(this.newForm)*/) {
        let saveData
        if(this.newForm) {
          saveData = data => autoApi.post(`/api/v1/users`,data);
        } else {
          saveData = data => autoApi.put(`/api/v1/users/${this.user.id}`, data);
        }
        // console.log(value)

        var user = this.user;
        delete user.roles;
        if(user.password === '' || user.password === null) {
          delete user.password;
        }

        if(this.user.lock !== true) {
          this.user.lock = false
        }
        console.log(typeof this.user.lock)

        saveData(this.user)
          .then(response => {
            if(response.data !== null) {
              this.getUsers();
              this.edit = false;
            }
          })
          .catch(err => console.error(err));
      }
    },

    // podgląd hasła
    clickForm(event) {
      let { on, key, obj, params } = event

      if (on === 'click' && key === 'password' && (params && params.tag) === 'append') {
        // toggle visibility of password control
        obj.schema.type === 'password' ? obj.schema.appendIcon = 'mdi-lock' : obj.schema.appendIcon = 'mdi-eye'
        obj.schema.type = obj.schema.type === 'password' ? 'text' : 'password'
      }
    },

    resetForm() {
      if(this.$refs.userForm != null) {
        this.$refs.userForm.resetValidation()
      }
    },

    initUserSchema() {
      if(this.newForm) {
        this.userSchema.password.rules = [rules.required, rules.min, rules.strength]
      } else {
        delete this.userSchema.password.rules
      }
    },

  },

}
</script>
