import { BehaviorSubject } from "rxjs";
import { config } from '../config'
import axios from "axios";
import { autoApi } from './autoApi'

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  currentUser: currentUserSubject.asObservable(),
  login,
  logout,
  hasRole,
  appLoggout,
  get currentUserValue() {
    return currentUserSubject.value;
  },

  get token() {
    // return currentUserSubject.value;
    return JSON.parse(localStorage.getItem("jwtToken"))
  },

  get logged() {
    return localStorage.getItem("jwtToken")!==null?true:false
  },

};

function hasRole(role) {
  const roles = localStorage.getItem("currentUser")!==null?localStorage.getItem("currentUser"):[];
  return roles.includes(role);
}

async function login(username, password) {
  return new Promise((resolve, reject) => {
    axios.post(`${config.apiURL}/api/v1/auth/login`, {
      "username": username,
      "password": password
    })
    .then(response => {
      localStorage.setItem('jwtToken', response.data.access_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
      localStorage.setItem('currentUser', JSON.stringify(response.data.user));

      currentUserSubject.next(response.data.user)
      console.log('Logged in');
      resolve(true);
    })
    .catch(error => {
      console.log(error);
      console.log('Cannot login');
      resolve(false);
      reject(error);
    })
  });
}

async function logout() {
  return new Promise((resolve, reject) => {
    autoApi.post(`${config.apiURL}/api/v1/auth/logout`, {
        refreshToken: localStorage.getItem("refreshToken"),
    })
    .then(() => {
      appLoggout()
      resolve(true);
    }).catch(error => {
      console.log(error);
      console.log('Cannot logout');
      resolve(false);
      reject(error);
    });
  })
}

// function authError(error) {
//   if(error.response) {
//     if(error.response.status===401) {
//       _loggout()
//     }
//   }
// }

function appLoggout() {
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('currentUser');
  document.location.href = "/";
  currentUserSubject.next(null);
}