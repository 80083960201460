import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import pl from 'vuetify/es5/locale/pl';
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VTextarea,
  VSheet,
  VBanner,
  VCard,
  VCombobox,
  VCheckbox,
  // VSelect,
  // VFileInput,
  // VIcon,
  // VBtn,
  // VBtnToggle,
  // VChip
  VSpacer,
  Touch,
  Intersect,
  Resize,
  VFlex,
  ClickOutside,
} from 'vuetify/lib';
// import { Touch, Intersect } from 'vuetify/lib/directives';


Vue.use(Vuetify, {
  iconfont: 'md',
  components: { VRow, VCol, VTextField, VTooltip, VTextarea, VSheet, VBanner, VFlex, VCard, VCombobox, VCheckbox, VSpacer},
  directives: { Touch, Intersect, Resize, ClickOutside },
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#9E9E9E',
        secondary: '#607D8B',
        accent: '#FFC107',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
  lang: {
    locales: { pl },
    current: 'pl',
  },
});
