<template>
  <div>
    <v-combobox
      v-model="model"
      :hide-no-data="!search"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="label"
      :return-objecta="returnObject"
      hide-selected
      :loading="loading"
      :search-input.sync="search"
      :disabled="disabled"
      :readonly="!selectValue"
      :success="success"
      @keyup="upperCase"
      :class="{ uppercase: uppercase }"
      :clearable="canEdit && !readonly"
      :error-messages="editError"
      @dblclick="copyText"
    >
      <!-- :error-messages="errorMessages" -->
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">Nowy:&nbsp;</span>
          <v-chip
            color="blue lighten-3"
            label
            small
          >
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>

      <template v-slot:selection="{ /*attrs, */item, /*parent, selected*/ }">
        <span @dblclick="copyText" v-if="dReadonly" class="pa-0">
          {{ item[itemText] }}
        </span>

          <!-- v-model="model[itemText]" -->
        <v-text-field
          v-model="modelText"
          @keyup="upperCaseEdit"
          flat
          v-if="!dReadonly"
          autofocus
          background-color="transparent"
          hide-details
          @keyup.enter="edit(item)"
          class="my-n3 ml-n3"
          solo
        ></v-text-field>
      </template>

      <template v-slot:append>
        <v-btn small icon v-if='canEditDict && !selectValue && !readonly' @click.stop.prevent="edit(model)">
          <v-icon>{{ dReadonly ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
        </v-btn>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { VCombobox } from 'vuetify/lib'
import pureObject from "@/mixins/pureObject"

export default {
  name: "aaCombobox3",
  extends: VCombobox,

  mixins: [pureObject],

  props: {
    value: {
      type: [Number, String, Object]
    },
    items: {
      type: Array,
      default: () => []
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    label: String,
    searchInput: [String, Number, Object],
    returnObject: Boolean,
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canEditDict: {
      type: Boolean,
      default: true,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dReadonly: true,
    model: null,
    search: null,
    showEditDialog: false,
    modelText: '',
    editing: null,
    editError: '',
  }),

  computed: {
    selectValue() {
      if(this.isEmptyObject(this.model)) {
        return true
      }
      return false
    }
  },

  watch: {
    model (val, prev) {
      // console.log(prev)
      // console.log(val)
      if (val === prev) return

      if(this.isEmptyPureObject(val)) {
        this.dReadonly = true;
      }

      if(val) {
        if (typeof val === 'string' && val !== '') {

          const item = this.items.find((obj => obj[this.itemText] == val));
          if(item) {
            this.model = item;
          } else {
            let v = {
                [this.itemText]: val,
            }
            this.items.push(v)
            this.model = v
          }
        }
      }

      if(this.model == this.value) {
        return;
      }
      this.$emit('input', this.model);
      // console.log(val[this.itemText])
    },

    modelText(val) {
      this.editError = ''
      this.$emit('update:searchInput', val);
    },

    items: {
      handler(newVal) {
        if(newVal.length>0) {
          const id = this.getValue(this.model);
          const item = newVal.find((obj => obj.id == id));
          if(item) {
            this.model = item;
          }

          if(!this.dReadonly) {
            // czegoś nie działa (jak w wyżej) w uproszczonym zapisie
            const edItem = newVal.find(((obj) => {
              if(obj[this.itemText] == this.modelText)
                return true;
              return false
            }));
            if(edItem) {
              this.editError = "Wartość już występuje"
            }
          }
        }
      },
      deep: true
    },

    value(val/*, prev*/) {
      // console.log(prev)
      // console.log(val)

      if(val == null) {
        this.model = null
      }

      // czyszczenie błedu edycji
      if(this.isEmptyPureObject(val)) {
        this.modelText = ''
      }

      if(typeof val === 'object' /*&& this.value !== 'undefined'*/) {
        //clear display for _ prefix
        if(typeof this.value[this.itemText] === 'string' && this.value[this.itemText][0] === '_') {
          this.initialValue[this.itemText] = ''
        }

        // sprawdzamy czy pusty obiekt
        if(this.isEmptyObject(val)) {
          this.model = null
        } else {
          this.model = val
        }

      }
    },

    search(val) {
      // console.log(val)
      if(typeof val !== "object") {
        // console.log(val)
        this.$emit('update:searchInput', val);
      }
    },
  },

  methods: {
    edit(/*item*/) {
      this.dReadonly = !this.dReadonly
      if(this.dReadonly) {
        this.model[this.itemText] = this.modelText
      } else {
        this.modelText = this.model[this.itemText]
      }
    },

    getText(item) {
      if(item) {
        return item[this.itemText]
      }
    },

    getValue(item) {
      if(item) {
        if(typeof item === 'number') {
          // console.log(item)
          return item;
        } else {
          const ret = item[this.itemValue];
          if(ret)
            return ret;

          return this.getText(item);
        }
      } else {
        return null;
      }
    },

    upperCase() {
      if(this.uppercase) {
        this.search = this.search.toUpperCase();
      }
    },

    upperCaseEdit() {
      if(this.uppercase) {
        this.modelText = this.modelText.toUpperCase();
      }
    },

    copyText() {
      console.log(this.getText(this.model))
      navigator.clipboard.writeText(this.getText(this.model))
    }

  },
};
</script>

<style >
.uppercase input{
  text-transform: uppercase;
}

.v-autocomplete.v-select.v-input--is-focused input {
  min-width: inherit !important;
}
</style>