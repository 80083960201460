<template>
  <nav>
    <v-app-bar app class="primary darken-2">
      <img src="logo-autoelektronika.svg" height="40" width="224" />
      <v-btn v-if="logged" text class="white--text" href="/entries">Serwis</v-btn>
      <v-btn v-if="logged && $can('view', 'menuTerminarz')" text class="white--text" href="/appointments">Terminarz</v-btn>
      <!-- -------------------------------------------------- -->
      <v-spacer></v-spacer>

      <v-menu
        offset-y
        v-if="logged && $can('view', 'menuAdmin')"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            class="white--text"
            text
            v-on="on"
          >
            Administrator
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="$can('view', 'menuPrintConfig')" href="/print-config">Konfiguracja wydruku</v-list-item>
          <v-list-item v-if="$can('view', 'menuUsers')" href="/users">Użytkownicy</v-list-item>
          <v-list-item v-if="$can('view', 'menuReport')" href="/report">Raport</v-list-item>
        </v-list>
      </v-menu>
      <!-- -------------------------------------------------- -->
      <v-dialog v-if="!logged" v-model="dialog" width="400">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark fab small v-on="on" class="ml-6">
            <v-icon>mdi-login-variant</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-img :aspect-ratio="16/9" src="motor-1381995_640.jpg">
            <v-row align="end" class="lightbox white--text pb-2 pl-0 ma-0 fill-height">
              <v-col>
                <div class="subheading"></div>
                <div class="mx-4">Logowanie do systemu</div>
              </v-col>
            </v-row>
          </v-img>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="mx-8"
                  label="Login"
                  v-model="vUserName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="mx-8"
                  label="Hasło"
                  v-model="vPassword"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                   v-on:keyup.enter="login"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" class="text-right">
                <v-btn class="mx-8" color="secondary" @click="login()">Login</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- ------------------------------------------- -->
      <v-btn color="primary" dark rounded  v-show="logged" @click="logout()" class="ml-6">
        {{ getUserName() }}
        &nbsp;
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
      <!-- ------------------------------------------- -->
    </v-app-bar>
  </nav>
</template>

<script>
import { authenticationService } from '../services/authentication.service'

export default {
  data() {
    return {
      dialog: false,
      show1: false,
      vUserName: "",
      vPassword: "",
      userName: '',
      logged: false,
    };
  },

  mounted() {
    // czy to potrzebne ???
    this.logged = authenticationService.logged
  },

  methods: {
    async login() {
      if(await authenticationService.login(this.vUserName, this.vPassword)) {
        this.logged = true;
        this.dialog = false;
        document.location.href = "/entries";
      }
    },

    async logout() {
      if(await authenticationService.logout(this.vUserName, this.vPassword)) {
        this.logged = false;
        this.dialog = false;
        // document.location.href = "/";
      }
    },

    getUserName() {
      return authenticationService.currentUserValue!==null ? authenticationService.currentUserValue.username : '';
    }
  },

};
</script>

<style>
.lightbox {
  box-shadow: 0 0 20px inset rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 172px);
}

/* for browser autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset !important;
}
</style>