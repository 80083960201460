<template>
  <v-card class="mx-auto mt-1 pt-5" style="max-width: 900px;">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-menu
          v-model="menuStartDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Data początkowa"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="menuStartDate = false"
            color="blue darken-1"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-menu
          v-model="menuEndDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="Data końcowa"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            @input="menuEndDate = false"
            color="blue darken-1"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-combobox
          v-model="mark"
          :items="marks"
          :hide-no-data="!searchMark"
          :search-input.sync="searchMark"
          item-text="name"
          item-value="id"
          :return-objecta="false"
          hide-selected
          :clearable="true"
        >
        </v-combobox>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
          <!-- :search="search"
          :options.sync="options"
          :server-items-length="totalItems"
          :sort-by="sortBy"
          :sort-desc="sortDesc" -->
        <v-data-table
          :headers="headers"
          :items="jobValues"
          :loading="loading"
          :items-per-page="25"
          :footer-props="{
            disableItemsPerPage:true,
            itemsPerPageOptions:[25]
          }"
          class="elevation-0"
          dense
        ></v-data-table>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <div>
          Wartość zakupu części: <strong>{{ partPurchaseValue }}</strong>
        </div>
        <div>
          Wartość sprzedaży części: <strong>{{ partSalesValue }}</strong>
        </div>
        <div>
          Różnica wartości sprzedaży i zakupu: <strong>{{ partSalesValue - partPurchaseValue}}</strong>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
// import debounce from "lodash.debounce";
import { authenticationService } from '../services/authentication.service'
import { autoApi } from '../services/autoApi'
import debounce from "lodash.debounce";

export default {
  name: "Report",

  data() {
    return {
      headers: [
        { text: "Pracownik", value: "name" },
        { text: "Wartość [pln]", value: "value" },
      ],
      jobValues: [],
      loading: false,
      menuStartDate: false,
      menuEndDate: false,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      partPurchaseValue: 0,
      partSalesValue: 0,
      loadingMark: false,
      marks: [],
      mark: null,
      searchMark: '',
    }
  },

    mounted() {
      authenticationService.currentUser.subscribe(this.setLogged)
      this.getJobValues()
    },

    watch: {
      startDate() {
        this.getJobValues();
      },
      endDate() {
        this.getJobValues();
      },
      searchMark: debounce(async function(val) {
        this.marks = await this.getMarks(val);
      }, 1000),
      mark() {
        console.log(this.mark)
        this.getJobValues();
      }
    },

    methods: {
      async getJobValues() {
        this.loading = true;

        let params = {
          startDate: this.startDate,
          endDate: this.endDate,
        }
        if(this.mark != null) {
          params.mark = this.mark.id
        }

        autoApi.get('/api/v1/reports/work-value', { params: params })
        .then(response => {
          this.jobValues = response.data;
          this.loading = false;
        })

        autoApi.get('/api/v1/reports/part-purchase-value', { params: params })
        .then(response => {
          this.partPurchaseValue = response.data;
        })

        autoApi.get('/api/v1/reports/part-sales-value', { params: params })
        .then(response => {
          this.partSalesValue = response.data;
        })

      },

      async getMarks(val) {
      this.loadingMark = true;
      // console.log(val);
      let ret = await autoApi.get(`/api/v1/marks`, {
        params: {
          search: val,
          // order: sort,
          skip: 0,
          take: 15
        }
      });

      this.loadingMark = false;
      return ret.data;
    },

    }
}
</script>