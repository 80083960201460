var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto mt-1 pt-5",staticStyle:{"max-width":"900px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data początkowa","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c('v-date-picker',{attrs:{"color":"blue darken-1"},on:{"input":function($event){_vm.menuStartDate = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data końcowa","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{attrs:{"color":"blue darken-1"},on:{"input":function($event){_vm.menuEndDate = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-combobox',{attrs:{"items":_vm.marks,"hide-no-data":!_vm.searchMark,"search-input":_vm.searchMark,"item-text":"name","item-value":"id","return-objecta":false,"hide-selected":"","clearable":true},on:{"update:searchInput":function($event){_vm.searchMark=$event},"update:search-input":function($event){_vm.searchMark=$event}},model:{value:(_vm.mark),callback:function ($$v) {_vm.mark=$$v},expression:"mark"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.jobValues,"loading":_vm.loading,"items-per-page":25,"footer-props":{
          disableItemsPerPage:true,
          itemsPerPageOptions:[25]
        },"dense":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',[_vm._v(" Wartość zakupu części: "),_c('strong',[_vm._v(_vm._s(_vm.partPurchaseValue))])]),_c('div',[_vm._v(" Wartość sprzedaży części: "),_c('strong',[_vm._v(_vm._s(_vm.partSalesValue))])]),_c('div',[_vm._v(" Różnica wartości sprzedaży i zakupu: "),_c('strong',[_vm._v(_vm._s(_vm.partSalesValue - _vm.partPurchaseValue))])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }