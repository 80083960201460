<template>
  <v-container >
  <ValidationObserver ref="obs1">
    <v-form ref="formEntry" :readonly="readonly">
      <v-row>
        <v-col cols="12" sm="6" v-if="$can('view', 'entry-AcceptanceDate')">
          <v-menu
            v-model="menu1"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="Entry.acceptanceDate"
                label="Data przyjęcia"
                prepend-icon="mdi-calendar"
                readonly
                required
                v-on="on"
                dense
                :clearable="!readonly"
              ></v-text-field>
            </template>
            <!-- <ValidationProvider name="acceptanceDate" rules="required" v-slot="{ errors, valid }"> -->
              <v-date-picker
                locale="pl"
                v-model="Entry.acceptanceDate"
                @input="menu2 = false"
                :readonly="readonly"
              ></v-date-picker>
                <!-- :error-messages="errors"
                :success="valid" -->
            <!-- </ValidationProvider> -->
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <ValidationProvider name="mileage" rules="integer" v-slot="{ errors, valid }">
            <v-text-field
              label="Przebieg"
              v-model.number="Entry.mileage"
              :error-messages="errors"
              :success="valid"
              :readonly="!$can('edit', 'entry-Mileage')"
              dense
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <ValidationProvider name="mileage" rules="required" v-slot="{ errors, valid }">
            <v-textarea
              rows="8"
              label="Opis usterki"
              v-model="Entry.description"
              :error-messages="errors"
              :success="valid"
              dense
              class="denseDesc"
              :readonly="!$can('edit', 'entry-Comments')"
            >
              <!-- <template v-slot:label>
                <div>Opis usterki</div>
              </template> -->
            </v-textarea>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea
            rows="8"
            label="Uwagi"
            v-model="Entry.comments"
            dense
            class="denseDesc"
            v-if="$can('view', 'entry-PrintInfo')"
            >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-textarea
            rows="8"
            label="Informacja o wykonanej naprawie"
            v-model="Entry.print_info"
            dense
            class="denseDesc"
            v-if="$can('view', 'entry-PrintInfo')"
            >
          </v-textarea>
        </v-col>

        <v-col cols="12" sm="3" v-if="$can('view', 'entry-Label')">
          <v-select
            v-model="Entry.label"
            :items="labels"
            item-text="name"
            item-value="id"
            label="Wyróżnienie"
            :return-object=true
            dense
            clearable
          >
            <template v-slot:item="data">
              <v-chip :color="data.item.color" dark>{{ data.item.name }}</v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="3" v-if="$can('view', 'entry-ReleaseDate')">
          <v-menu
            v-model="menu2"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="Entry.releaseDate"
                label="Data wydania"
                prepend-icon="mdi-calendar"
                readonly
                dense
                v-on="on"
                :clearable="!readonly"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-PL"
              v-model="Entry.releaseDate"
              @input="menu2 = false"
              :readonly="readonly"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-form>
  </ValidationObserver>
  </v-container>
</template>

<script>
import { autoApi } from '../services/autoApi'
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "EntryForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    entry: Object,
    appointmentDate: Date,
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu1: false,
      menu2: false,

      Entry: {},
      labels: [],
    }
  },

  // to support the first opening of the component
  created: function () {
    this.getLabels()
  },

  watch: {
    entry: {
      handler: function(val) {
        this.Entry = val
      },
      deep: true
    },
    Entry: {
      handler: function(val) {
        this.$emit('update:entry', val);
      },
      deep: true
    },

    appointmentDate: {
      handler: function(val) {
        // console.log('--= appointmentDate =--')
        // console.log(val)
        if(val) {
          if(this.Entry) {
            // if not null
            this.Entry.appointmentDate = val
          } else {
            this.Entry = {
              appointmentDate: val,
            }
          }
        }
      },
      deep: true,
      // immediate: true
    },
  },

  methods: {
    validate() {
      return this.$refs.obs1.validate()
    },

    reset() {
      // cleaning form

      // if appointmentDate is given fron paren set it into component
      if(this.appointmentDate) { // tu cośjest nie tak
        this.Entry = {
          appointmentDate: this.appointmentDate,
        }
      } else {
        this.Entry = {
          acceptanceDate: new Date().toISOString().split("T")[0],
        }
      }

      // console.log(this.Entry)
      return this.$refs.obs1.reset()
    },

    getLabels() {
      autoApi
        .get(`/api/v1/labels`, {
        })
        .then(response => {
          this.labels = response.data;
        })
        .catch(err => {
          console.log(err);
        })
    },

  }
};
</script>

<style>
</style>