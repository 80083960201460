import { required, email, min, max, integer, min_value, numeric, double } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Pole jest wymagane"
});

extend("max", {
  ...max,
  message: "Pole nie może być dłuższe niż {length}"
});

extend("min", {
  ...min,
  message: "Pole nie może być kródsze niż {length}"
});

extend("integer", {
  ...integer,
  message: "Pole musi być liczbą"
});

extend("min_value", {
  ...min_value,
  message: "Za mała wartość"
});

extend("numeric", {
  ...numeric,
  message: "Pole musi być liczbą"
});

extend("double", {
  ...double,
  message: "Pole musi być liczbą"
});

extend("email", {
  ...email,
  message: "Pole musi zawierać poprawny e-mail"
});
