<template>
  <v-dialog v-model="show" max-width="1200" persistent class="my-1" scrollable>
    <v-card>
      <v-card-title style="padding: 0 !important;">
        <v-tabs v-model="tab" background-color="secondary" @change="tabChange">
          <v-tab>
            <span style="word-break: normal;">Zgłoszenie</span>

            <v-menu
              v-if="date"
              v-model="menuApDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="apDate"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dark
                  class="ml-4 mr-2"
                  style="min-width:115px"
                ></v-text-field>
              </template>
              <v-spacer></v-spacer>
              <v-date-picker
                v-model="apDate"
                @input="menuApDate = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-if="date"
              ref="menuTime"
              v-model="menuApTime"
              :return-value.sync="apTime"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="apTime"
                  prepend-inner-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dark
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="apTime"
                v-if="menuApTime"
                no-title
                format="24hr"
                :allowed-minutes="allowedStep"
                @click:minute="$refs.menuTime.save(apTime)"
              ></v-time-picker>
            </v-menu>
          </v-tab>
          <v-tab :disabled="historyTab" >Historia</v-tab>
          <v-tab v-if="$can('print', 'entry')" key="drukowanie">Drukuj</v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text class="entryCardText">
        <v-tabs-items v-model="tab">
          <!-- =============================================================================================== -->
          <v-tab-item eager>
            <v-expansion-panels accordion v-model="panelEntry" >
            <v-expansion-panel>
              <v-expansion-panel-header color='grey lighten-3' class="py-1">
                <span>
                  <v-badge
                    bordered
                    color="error"
                    icon="mdi-exclamation-thick"
                    offset-y="10"
                    offset-x="-2"
                    v-model="errorTab0"
                  >
                    Pojazd
                    <span v-if="isOpen" style="color:red; margin-left:30px; font-weight:600">Zlecenie otwarte</span>
                    <span v-if="isLocked" style="color:red; margin-left:30px; font-weight:600">Zlecenie jest edytowane</span>
                  </v-badge>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <v-row>
                  <v-col cols="12" md="6">
                    <ValidationObserver ref="obs0">
                    <v-form ref="formCar" :readonly="isLocked">
                      <v-row>
                        <v-col cols="12" md="4">
                          <!-- <ValidationProvider name="plate" rules="required" v-slot="{ errors, valid }"> -->
                            <aaCombobox3
                              label="Numer rejestracyjny"
                              :items.sync="cars"
                              :search-input.sync="searchPlate"
                              :loading="loadingPlate"
                              item-text="plate"
                              item-value="id"
                              v-model="vCar"
                              :return-object="true"
                              :new-value="newValue"
                              :can-edit-dict="$can('edit', 'plate')"
                              :can-edit="$can('edit', 'plate')"
                              :auto-select-first="false"
                              uppercase
                              :readonly="isLocked"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            label="Pojemność"
                            v-model="vCar.capacity"
                            :readonly="!$can('edit', 'capacity')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            label="Rok prod."
                            v-model="vCar.productionYear"
                            :readonly="!$can('edit', 'productionYear')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ValidationProvider name="mark" rules="required" v-slot="{ errors, valid }">
                          <aaCombobox3
                            label="Marka"
                            :items.sync="marks"
                            :search-input.sync="searchMark"
                            :loading="loadingMark"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            v-model="vCar.mark"
                            :return-object="true"
                            @change="markChange"
                            :new-value="newValue"
                            :disabled="disableMark"
                            :readonly="isLocked"
                            :can-edit-dict="$can('edit', 'mark')"
                            :can-edit="$can('edit', 'mark')"
                          />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider name="model" rules="required" v-slot="{ errors, valid }">
                          <aaCombobox3
                            label="Model"
                            :items.sync="models"
                            :search-input.sync="searchModel"
                            :loading="loadingModel"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            v-model="vCar.model"
                            :return-object="true"
                            :new-value="newValue"
                            :disabled="disableMark"
                            :readonly="isLocked"
                            @change="modelChange"
                            :can-edit-dict="$can('edit', 'mark')"
                            :can-edit="$can('edit', 'mark')"
                          ></aaCombobox3>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <aaCombobox3
                            label="VIN"
                            :items="cars"
                            :loading="loadingVin"
                            :search-input.sync="searchVin"
                            :return-object="true"
                            :new-value="newValue"
                            item-text="vin"
                            item-value="id"
                            v-model="vCar"
                            :can-edit-dict="$can('edit', 'vin')"
                            :can-edit="$can('edit', 'vin')"
                            uppercase
                            :readonly="isLocked"
                          ></aaCombobox3>
                        </v-col>
                      </v-row>
                    </v-form>

                    </ValidationObserver>
                  </v-col>
                  <v-col cols="12" md="6" v-if="$can('view', 'contact')">
                    <v-form class="border-frame" :readonly="isLocked">
                      <v-form-base
                        :model="vContact"
                        :schema="contactSchema"
                        :col=12
                      >
                      </v-form-base>
                    </v-form>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header color='grey lighten-3' class="py-1">
                <span>
                  <v-badge
                    bordered
                    color="error"
                    icon="mdi-exclamation-thick"
                    offset-y="10"
                    offset-x="-2"
                    v-model="errorTab1"
                  >
                    Przyjęcie
                  </v-badge>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <EntryForm :readonly="isLocked" :entry.sync="vEntry" :appointmentDate="acDate" ref="entryFormRef"/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :disabled="disableJobs" >
              <v-expansion-panel-header color='grey lighten-3'>Wykonane naprawy {{ valueJobs }}</v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <JobsForm :readonly="isLocked" :entryId="vEntryId" v-model="valueJobs"/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- :disabled="disableParts" -->
            <v-expansion-panel  v-if="$can('view', 'parts')">
              <v-expansion-panel-header color='grey lighten-3'>Użyte części {{ valueParts }}</v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <PartsForm :readonly="isLocked" :parts.sync="vParts" :valueParts.sync="valueParts" :partsToSave.sync="partsToSave" :entry.sync="vEntry" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            </v-expansion-panels>
          </v-tab-item>
          <!-- ------------ Historia --------------------------------------------------------------- -->
          <v-tab-item eager>
            <!-- <v-card flat title> -->
              <v-row>
                <v-item-group
                  v-model="historyWindow"
                  class="shrink ml-2 mt-3"
                  mandatory
                  tag="v-flex"
                >
                  <v-item
                    v-for="n in historyModel.length"
                    :key="n"
                    v-slot="{ active, toggle }"
                  >
                    <div>
                      <v-btn
                        :input-value="active"
                        @click="toggle"
                        text
                      >
                        {{historyModel.length>0?historyModel[n-1].acceptanceDate:null}}
                      </v-btn>
                    </div>
                  </v-item>
                </v-item-group>
                <v-col class="pa-0 mt-2" style="width: 93%">
                  <v-window
                    v-model="historyWindow"
                    vertical
                  >
                    <v-window-item
                      v-for="n in historyModel.length"
                      :key="n"
                    >
                      <v-card flat>
                        <v-card-text>
                          <v-form class="border-frame" readonly>
                            <v-form-base
                              :model="historyModel[n-1]"
                              :schema="historySchema"
                              :col=12
                            >
                              <template #slot-top-key-form-base-jobs>
                                <div class="array-header col col-12">
                                  Wykonane naprawy
                                </div>
                              </template>
                              <template #slot-bottom-key-form-base-jobs>
                                <div style="margin-bottom:7px"></div>
                              </template>

                              <template #slot-top-key-form-base-parts>
                                <div class="array-header col col-10">
                                  Użyte części
                                </div>
                                <div class="array-header col col-1">
                                  Cena
                                </div>
                                <div class="array-header col col-1">
                                  Klient
                                </div>
                              </template>

                              <!-- <template #slot-item-key-form-base-jobs="{obj, idx, item}">
                                <div class="col-10">{{idx}}</div>
                                <div class="col-1">{{item}}</div>
                                <div class="col-1">{{obj.key}}</div>
                              </template> -->

                            </v-form-base>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-window-item>
                  </v-window>
                </v-col>
              </v-row>
            <!-- </v-card> -->
          </v-tab-item>

          <v-tab-item eager>
            <v-card flat title key="drukowanie">
              <Print :car="vCar" :entry="vEntry" :parts="vParts" :contact="vContact" :print.sync="print" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- zwracanie wartości -->
        <v-btn text color="secondary darken-2" @click="$emit('update:show', false);">
          <span>Zamknij</span>
          <!-- <v-icon>mdi-close-circle</v-icon> -->
        </v-btn>
        <v-btn v-if="tab===0 && !isLocked" text color="secondary darken-2" @click="saveEntry();">
          <span>Zapisz</span>
          <!-- <v-icon>mdi-content-save</v-icon> -->
        </v-btn>
        <v-btn v-if="tab===2" text color="secondary darken-2" @click="print=true">
          <span>Drukuj</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { server } from "../helper";
// import axios from "axios";
import { autoApi } from '../services/autoApi'
import debounce from "lodash.debounce";
import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
// import { required, min } from 'vee-validate/dist/rules';
import JobsForm from "./JobsForm"
import PartsForm from "./PartsForm"
import EntryForm from './EntryForm'
// import aaCombobox from './aaComponents/aaCombobox'
import aaCombobox3 from './aaComponents/aaCombobox3'
import VFormBase from "vuetify-form-base"
import Print from "./Print.vue"
import pureObject from "@/mixins/pureObject"
import { authenticationService } from '../services/authentication.service'

export default {
  name: "Entry",
  components: {
    ValidationProvider,
    ValidationObserver,
    JobsForm,
    PartsForm,
    EntryForm,
    // aaCombobox,
    VFormBase,
    Print,
    aaCombobox3,
  },

  mixins: [pureObject],

  data: () => ({
    timer: null,
    isLocked: false,
    isOpen: false,
    newValue: false,
    historyTab: false,
    menuApDate: false,
    menuApTime: false,
    apTime: null,
    apDate: null,
    historyEntries: [],
    historyWindow:0,
    historyModel: [],
    vContact: {},
    contactSchema: {
      firstName: {
        type: "text",
        label: "Imię",
        col: 6,
        class: "mr-2",
      },
      lastName: {
        type: "text",
        label: "Nazwisko",
        col: 6,
        class: "mr-2",
      },
      name: {
        type: "text",
        label: "Nazwa",
        col: 12,
        class: "mr-2",
      },
      email: {
        type: "email",
        label: "e-mail",
        col: 6,
        class: "mr-2",
      },
      phone: {
        type: "text",
        label: "Telefon",
        col: 6,
      }
    },
    historySchema: {
      releaseDate: {
        type: "text",
        label: "Data wydania",
        // outlined: true,
        // solo: true,
        dense: true,
        col: 4,
        class: "mr-2",
      },
      mileage: {
        type: "text",
        label: "Przebieg",
        // outlined: true,
        dense: true,
        col: 4,
        class: "mr-2",
      },
      description: {
        type: "textarea",
        label: "Opis usterki",
        // outlined: true,
        dense: true,
        class: "denseText mr-2",
        // autoGrow: true,
        noResize: true,
        col:  {
          sm: 6,
          cols: 12
        },
      },
      print_info: {
        type: "textarea",
        label: "Informacje do wydruku wydania pojazdu",
        class: "denseText",
        can: ['view', 'entry-PrintInfo'],
        dense: true,
        noResize: true,
        col:  {
          sm: 6,
          cols: 12
        }
      },
      jobs: {
        type: 'array',
        col: 12,
        // label: "Wykonane naprawy",
        schema: {
          jobDescription: {
            type: "textarea",
            // label: "Naprawa",
            noResize: true,
            rows: 3,
            autoGrow: true,
            flat: true,
            class: "denseText",
            col: {
              sm: 9,
              cols: 2
            },
          },
          userName: {
            type: "text",
            // label: "Pracownik",
            col: 3,
            // flat: true,
            // solo: true,
            dense: true,
            // class: "mr-2"
          },
        }
      },
      parts: {
        type: 'array',
        col: 12,
        can: ['view', 'parts'],
        schema: {
          part: {
            type: "text",
            dense: true,
            col: 10,
            class: "mr-2"
          },
          value: {
            type: "text",
            // label: "Cena",
            dense: true,
            col: 1,
            class: "mr-2"
          },
          value2: {
            type: "text",
            // label: "Cena",
            dense: true,
            col: 1,
            class: "mr-2"
          },
        }
      }
    },
    tab: 0,
    errorTab0: false,
    errorTab1: false,

    menuJobTime: false,
    panelEntry: 0,
    loadingPlate: false,
    loadingVin: false,
    loadingMark: false,
    loadingModel: false,
    vEntryId: null,

    vCar: {},
    vEntry: {},
    vParts: [],
    partsToSave : [],
    valueJobs: '',
    valueParts: '',

    acDate: null,

    searchPlate: null,
    searchVin: null,
    searchMark: null,
    searchModel: null,
    marks: [],
    models: [],
    cars: [],
    stopVin: 0,
    print: false,

  }),

  props: {
    entryId: Number,
    show: Boolean,
    reload: Boolean,
    newForm: Boolean,
    date: Date,
  },

  mounted() {
    function parseSchema(env, obj) {
        if (obj["can"]) {
          obj["hidden"] = !env.$can(obj["can"][0], obj["can"][1]);
          delete obj["can"]
        }

        for (let key in obj) {
          if (typeof obj[key] === 'object') {
            parseSchema(env, obj[key]);
          }
        }
      }

      parseSchema(this, this.historySchema)
  },
  // beforeMount() {},
  // updated() {},
  computed: {
    disableJobs: function() {
      return this.vEntryId?false:true;
    },

    disableParts: function() {
      return this.vEntryId?false:true;
    },

    entryForm: function() {
      return this.$refs.entryFormRef
    },

    //jeśli się sprawdzi ustawić na stałę włączone i tyle
    disableMark: function() {
      if(typeof this.vCar === 'object') {
        return false;
      }
      return false;
      // return true;
    },

  },

  watch: {
    apDate: {
      handler: function(val) {
        if(val) {
          const robDate = Date.parse(this.apDate+' '+this.apTime)
          this.acDate = new Date(robDate);
        }
      }
    },
    apTime: {
      handler: function(val) {
        if(val) {
          const robDate = Date.parse(this.apDate+' '+this.apTime)
          this.acDate = new Date(robDate);
        }
      }
    },

    // change input date
    date: {
      handler: function(val) {
        if(val) {
          // console.log(this.date.getTime())
          const robDate = new Date(this.date)
          this.apDate = robDate.toISOString().slice(0, 10);
          this.apTime = robDate.toTimeString().substr(0, 5);

          this.acDate = robDate;
        }
      }
    },

    searchPlate: debounce(async function(val) {
      // console.log(val)
      if(val!=='' && val !==null && this.testVal(val, this.vCar, 'plate')) {
        this.loadingPlate = true
        this.cars = await this.getCars({"plate": val})
        this.loadingPlate = false
      }
    }, 500),

    searchVin: debounce(async function(val) {
      // console.log(val)
      // console.log(this.stopVin)
      // if(val!=='' && val!==null && this.stopVin<=0 && this.testVal(val, this.vCar, 'vin')) {
      if(val!=='' && val!==null && this.testVal(val, this.vCar, 'vin')) {
        this.loadingVin = true
        this.cars = await this.getCars({"vin": val})
        this.loadingVin = false
      } else {
        if(this.stopVin>0) this.stopVin--;
      }
    }, 1000),

    searchMark: debounce(async function(val) {
      // console.log(val)
      // if(typeof val === 'string') {
        this.marks = await this.getMarks(val);
      // }
    }, 1000),

    vCar: {
      async handler(newVal, oldVal) {
        // console.log(oldVal)
        // console.log(newVal)

        if(newVal === null) {
          this.vContact= {}
          this.vCar={};
          return
        }

        if(typeof newVal === 'object') {
          // console.log(this.pureObject(newVal))

          // czyszczenie modelu gdy marka czysta a car nie jest pusty
          if(!this.isEmptyPureObject(newVal) && newVal.mark === null) {
            this.vCar.model = null
          }

          autoApi.get(`/api/v1/cars/${this.vCar.id}/isopen`)
          .then(response => {
            if(response.data && this.entryId==0) {
              this.isOpen = true;
            } else {
              this.isOpen = false;
            }
          })

          if(newVal.contactId !== oldVal.contactId) {
            autoApi.get(`/api/v1/contacts/`+this.vCar.contactId).then(response => {
              // if no data retry enpty object
              this.vContact = response.data===''?{}:response.data
            })
          }

          if(typeof newVal === 'object' && typeof newVal.mark !== 'undefined' && typeof newVal.mark.model !== 'undefined') {
            this.models = newVal.mark.model
          } else {
            this.models = []
          }

          this.stopVin = 2; // czy to obecnie jest potrzebne???

          if(newVal.id) {
            let count = await this.getEntriesCountByCar(newVal.id)
            this.historyTab = count > 0 ? false : true

          } else {
            this.historyTab = true
          }
        }

      },
      deep: true
    },

    // isLocked(val) {
    //   console.log(val)
    //   // if(val === true) {
    //     this.$refs.formCar.disabled = true;
    //     console.log(this.$refs.formCar)
    //   // }
    // },

    async vEntryId(val) {
      // console.log(val)
      if (val > 0) {
        this.vCar = await this.getCarByEntry(val); // tu nie potrzebna raczej funkcja - zrobić jak niżej
        autoApi.get(`/api/v1/contacts/`+this.vCar.contactId).then(response => {
          // if no data retry enpty object
          this.vContact = response.data===''?{}:response.data
        })
      } else {
        this.clearForm();
      }
    },

    // service of the opened form
    async show(val) {
      // console.log(val)
      this.newValue = this.newForm
      this.clearForm();
      if (!val) {
        this.tab = 0;
        if(!this.isLocked) {
          clearInterval(this.timer)
          await autoApi.delete(`/api/v1/entries/${this.entryId}/lock`);
        }
      } else {
        if (this.entryId != 0) {
          this.vEntryId = this.entryId;
          this.vEntry = await this.getEntry(this.entryId)
          this.vParts = await this.getParts(this.entryId)

          autoApi.get(`/api/v1/entries/${this.entryId}/lock`)
          .then(response => {
            this.isLocked = response.data
            if(!this.isLocked) {
              autoApi.post(`/api/v1/entries/${this.entryId}/lock`);
              this.timer = setInterval(()=>{
                autoApi.post(`/api/v1/entries/${this.entryId}/lock`);
              }, 60*1000)
            }
          })

        }
      }
    }
  },
  methods: {
    allowedStep: m => m % 15 === 0,

    clearForm() {
      // console.log('--= clearForm =--')
      this.vEntryId = null
      this.vEntry = {}
      this.historyTab = false;
      this.cars = []
      this.isOpen = false;

      this.$nextTick(() => {

        this.vCar = {};
        this.searchMark = null
        this.searchModel = null
        this.vContact= {}
        this.partsToSave = []
        this.vParts = []

        this.panelEntry = 0

        this.errorTab0 = false
        this.errorTab1 = false

        this.$refs.obs0.reset();
        this.entryForm.reset();
      });
    },

    async getEntriesCountByCar(val) {
      var response = await autoApi.get(`/api/v1/carentries/count/${val}`)
      return response.data
    },

    markChange(event) {
      switch(event.initiator) {
        case 'combobox':
          console.log('combo')
          if(typeof this.vCar === 'object') {
            this.vCar.modelId = null;
            delete this.vCar.model
          }
          break;
        case 'text':
          // update marki
          console.log('Update model')
          this.saveMark(event.value, true)
          // .then(response => {
          //   console.log(response)
          // });
          break;
      }
    },

    modelChange(event) {
      switch(event.initiator) {
        case 'text':
          // update marki
          console.log('Update model')
          console.log(event.value)
          this.saveModel(event.value, true)
          // .then(response => {
          //   console.log(response)
          // });
          break;
      }
    },

    async getMarks(val) {
      this.loadingMark = true;
      // console.log(val);
      let ret = await autoApi.get(`/api/v1/marks`, {
        params: {
          search: val,
          // order: sort,
          skip: 0,
          take: 15
        }
      });

      this.loadingMark = false;
      return ret.data;
    },

    async getCars(val) {
      // this.loadingPlate = true;
      // console.log(val);
      let params = {
          skip: 0,
          take: 15
      }
      Object.assign(params, val)
      // console.log(params)

      let ret = await autoApi.get(`/api/v1/cars`, {
        params: params
      });
      let items = ret.data;

      // this.loadingPlate = false;
      return items;
    },

    async getCarByEntry(entryId) {
      const ret = await autoApi.get(`/api/v1/entries/`+entryId+'/car')
      return ret.data;
    },

    async getEntry(val) {
      let response = await autoApi.get(`/api/v1/entries/${val}`)
      // console.log(response.data)
      return response.data
    },

    async getParts(id) {
      // this.loadingParts = true;
      let response = await autoApi.get(`/api/v1/entries/${id}/parts`, {  })
      return response.data
    },

    async saveMark(value, update=false) {
      let markData;
      if(typeof value === 'object') {
        markData ={
          "name": value.name
        }
      } else {
          markData ={
            "name": value
          }
      }

      let saveData;
      if(!update) {
        saveData = data => autoApi.post(`/api/v1/marks`,data);
      } else {
        saveData = data => autoApi.put(`/api/v1/marks/${value.id}`, data);
      }

      return new Promise((resolve, reject) => {
        saveData(markData)
          .then(response => resolve(response))
          .catch(err => reject(err));
      })
    },

    async saveModel(value, update=false) {
      let modelData;
      if(typeof value === 'object') {
        modelData ={
          "name": value.name
        }
      } else {
        modelData ={
          "name": value
        }
      }

      let saveData;
      if(!update) {
        saveData = data => autoApi.post(`/api/v1/marks/${this.vCar.markId}/models`,data);
      } else {
        saveData = data => autoApi.put(`/api/v1/marks/models/${value.id}`, data);
      }

      return new Promise((resolve, reject) => {
        saveData(modelData)
          .then(response => resolve(response))
          .catch(err => reject(err));
      })
    },

    async saveContact(value, create=true) {
      let saveData
      if(create) {
        saveData = data => autoApi.post(`/api/v1/contacts`,data);
      } else {
        saveData = data => autoApi.put(`/api/v1/contacts/${this.vContact.id}`, data);
      }
      // console.log(value)

      return new Promise((resolve, reject) => {
        saveData(value)
          .then(response => resolve(response))
          .catch(err => reject(err));
      })
    },

    async saveEntry() {
      this.errorTab0 = !(await this.$refs.obs0.validate());
      this.errorTab1 = !(await this.entryForm.validate())

      // console.log(this.vCar)

      if(!this.isOpen && !this.errorTab0 && !this.errorTab1) {

        // if(typeof this.vCar.markId==='string') {
        // nowa marka
        if(typeof this.vCar.mark == 'object' && typeof this.vCar.mark.id === 'undefined') {
          let response =  await this.saveMark(this.vCar.mark);
          console.log(response);
          this.marks.push(response.data);
          this.vCar.markId = response.data.id;
        } else {
          this.vCar.markId = this.vCar.mark.id
        }

        // if(typeof this.vCar.modelId==='string') {
        // mowy model
        if(typeof this.vCar.model == 'object' && typeof this.vCar.model.id === 'undefined') {
          console.log('New model')
          let response =  await this.saveModel(this.vCar.model);
          console.log(response);
          this.models.push(response.data);
          this.vCar.modelId = response.data.id;
        } else {
          this.vCar.modelId = this.vCar.model.id
        }

        // console.log(this.vContact)
        const ret = await this.saveContact(this.vContact, this.vContact.id!=='undefined')
        this.vContact = ret.data

        // console.log(this.vCar)

        if(typeof this.vCar==='object') {
          const carData = {
            "plate": !this.vCar.plate?'_'+Date.now():this.vCar.plate,
            "markId": this.vCar.markId,
            "modelId": this.vCar.modelId,
            "vin": this.vCar.vin,
            "capacity": this.vCar.capacity,
            "engine": this.vCar.engine,
            "productionYear": this.vCar.productionYear,
            "contactId": this.vContact.id,
            "description": this.vCar.description
          }

          let saveCar;
          if(typeof this.vCar.id==='undefined') {
            console.log('nowe auto')
            saveCar = data => autoApi.post(`/api/v1/cars`,data);
          } else {
            // console.log('update auto')
            saveCar = data => autoApi.put(`/api/v1/cars/${this.vCar.id}`,data);
          }

          saveCar(carData)
            .then((response) => {
              // console.log(response)
              this.vCar = response.data;

              this.saveEntryForm()
                .then(ret => {
                  if(ret) {
                    this.$emit('update:reload', true);
                    this.$emit('update:show', false);
                    this.clearForm()
                  }
                })
            })
            .catch(err => {
              console.log(err);
            })
        }
      }
      // this.clearForm()
    },

    async saveEntryForm() {
      let entry = {
        acceptanceDate: this.vEntry.acceptanceDate,
        releaseDate: this.vEntry.releaseDate,
        description: this.vEntry.description,
        comments: this.vEntry.comments,
        mileage: this.vEntry.mileage,
        print_info: this.vEntry.print_info,
        label: this.vEntry.label?this.vEntry.label:null,
        discount: this.vEntry.discount,
        printValues: this.vEntry.printValues,
        printIndex: this.vEntry.printIndex,
        carId: this.vCar.id,
      }


      if(this.vEntry.appointmentDate) {
        entry.appointmentDate = this.vEntry.appointmentDate //.getTime()
      }

      let submitEntry;
      if(this.entryId) {
        submitEntry = data => autoApi.put(`/api/v1/entries/${this.entryId}`, data)
      } else {
        submitEntry = data => autoApi.post(`/api/v1/entries`, data)
      }

      const response = await submitEntry(entry)
      if(!this.isEmptyPureObject(response)) {
        // console.log(response.data.id)
        const entryId = response.data.id;

        this.partsToSave.forEach(async item => {
          if(item > 0) {
            // zapisywanko
            item = item-1
            let submitPart;
            if(typeof this.vParts[item].id =="undefined") {
              submitPart = (data) => autoApi.post(`/api/v1/entries/${entryId}/parts`, data);
            } else {
              submitPart = (data) => autoApi.put(`/api/v1/entries/${entryId}/parts/${this.vParts[item].id}`, data);
            }

            try {
              await submitPart({part: this.vParts[item]}) // index-1
            } catch(err) {
                console.error(err)
                authenticationService.authError(err);
            }

          } else {
            // kasowanko
            item = (-item)-1
            console.log(item)
            autoApi
            .delete(`/api/v1/entries/${entryId}/parts/${this.vParts[item].id}`)
              .catch(err=>{
                console.error(err);
                authenticationService.authError(err);
              })
          }

        })
        return true
      }
      this.partsToSave = []
      return false

      // try {
      //   if(this.entryId) {
      //     entry.carId = this.vCar.id
      //     await autoApi.put(`/api/v1/entries/${this.entryId}`, entry)
      //     return true;
      //   } else {
      //     entry.carId = this.vCar.id
      //     console.log('=== nowe przyjęcie ===')
      //     autoApi.post(`/api/v1/entries`, entry)
      //     .then(response => {
      //       if(response) {

      //       }
      //     })
      //     return true
      //   }
      // } catch(err) {
      //   console.log(err)
      // }
      // return false
    },

    async tabChange(item) {

      if(item == 1) { // history
        let response = await autoApi.get(`/api/v1/carentries/${this.vCar.id}`, {
          // params: {id: this.vCar.id}
        })

        // response.data.splice(-1,1) // zdejmujemy ostatni
        response.data.reverse() // odwracamy kolejnosc
        this.historyEntries = response.data

        let usersArr  = await autoApi.get(`/api/v1/users`, { })

        this.historyModel = []
        this.historyEntries.forEach(async item => {
          let jobArr = await autoApi.get(`/api/v1/entries/${item.id}/carjobs`)
          let partArr = await autoApi.get(`/api/v1/entries/${item.id}/parts`)

          Object.keys(jobArr.data.jobs).forEach(key=>{
            let userId = jobArr.data.jobs[key].user.id
            let user = usersArr.data.find(m => m.id == userId);
            jobArr.data.jobs[key] = {
              ...jobArr.data.jobs[key],
              userName: user.firstName+' '+(user.lastName?user.lastName:"")
            }
          });

          this.historyModel.push({
            acceptanceDate: item.acceptanceDate,
            releaseDate: item.releaseDate,
            mileage: item.mileage,
            description: item.description,
            print_info: item.print_info,
            jobs: jobArr.data.jobs,
            parts: partArr.data,
          })

          // sortowanko daty
          this.historyModel.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.acceptanceDate) - new Date(a.acceptanceDate);
          });

          // console.log(partArr)
          // console.log(this.historyModel)
        });
      }
    },

    testVal(val, obj, param) {
      if(typeof obj === 'object') {
        if(typeof obj[param] !== 'undefined') {
          if(obj[param] === val) {
            // console.log(`test ${param} = FALSE`)
            return false;
          }
        }
      }

      // console.log(`test ${param} = TRUE`)
      return true;
    }

  }
};
</script>

<style>
</style>