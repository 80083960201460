<template>
  <v-form @submit.prevent :readonly="readonly">
    <v-data-table
      :headers="cHeadJobs"
      :items="carJobs"
      class="elevation-0 mr-n6 mb-n4"
      tile
      :hide-default-footer="hideFooter"
      dense
      no-data-text
      :loading="loadingJobs"
      :footer-props="{
        disableItemsPerPage:true,
        itemsPerPageOptions:[10]
      }"
    >
      <template v-slot:[`item.jobDescription`]="{ item }">
        <span class="info-item">{{ item.jobDescription }}</span>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        {{ item.user.name }}
      </template>
      <template v-slot:[`item.createDate`]="{ item }">
        <span v-if="item.createDate===null" >  </span>
        <span v-else>{{ new Date(item.createDate).toISOString().replace('T', ' ').substr(0, 19) }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }" >
        <v-icon small class="mr-2" @click="editItem(item)" v-if="isOwner(item) && !readonly">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)" v-if="isOwner(item) && !readonly">mdi-delete</v-icon>
      </template>
      <template v-slot:top>
        <v-row>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="600px"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="readonly===false"
                class="mt-7 mr-4"
                color="primary"
                dark
                depressed
                small
                v-bind="attrs"
                v-on="on"
              >
                Dodaj czynność
              </v-btn>
            </template>

            <v-card>
              <ValidationObserver ref="obs0" :readonly="readonly">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <ValidationProvider name="jobDescription" rules="required" v-slot="{ errors, valid }">
                      <v-textarea
                        v-model="vJob.jobDescription"
                        label="Naprawa"
                        rows="8"
                        :error-messages="errors"
                        :success="valid"
                      ></v-textarea>
                      </ValidationProvider>

                      <v-combobox
                        v-if="$can('edit', 'carJob')"
                        :items="users"
                        label="Pracownik"
                        :loading="loadingUsers"
                        item-value="id"
                        item-text="name"
                        v-model="vJob.user"
                        :return-object=true
                      ></v-combobox>

                      <v-menu
                        ref="menuJobTime"
                        v-model="menuJobTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="vJob.time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <ValidationProvider name="time" rules="required" v-slot="{ errors, valid }">
                          <v-text-field
                            v-model="vJob.time"
                            readonly
                            v-on="on"
                            label="Czas"
                            :error-messages="errors"
                            :success="valid"
                          ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-time-picker
                          v-if="menuJobTime"
                          v-model="vJob.time"
                          full-width
                          format="24hr"
                          @click:minute="$refs.menuJobTime.save(vJob.time)"
                        ></v-time-picker>
                      </v-menu>

                      <ValidationProvider name="value" rules="double" v-slot="{ errors, valid }">
                      <v-text-field
                        v-if="$can('edit', 'carJob')"
                        v-model.number="vJob.value"
                        label="Wartość"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                      </ValidationProvider>

                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Anuluj
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="submitJobs(edit)"
                >
                  Zapisz
                </v-btn>
              </v-card-actions>
              </ValidationObserver>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
// import { server } from "../helper";
// import axios from "axios";
import { autoApi } from '../services/autoApi'
// import debounce from "lodash.debounce";
import { authenticationService } from '../services/authentication.service';
import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  name: "JobsForm",
  props: {
    entryId: Number,
    value: String,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      headJobs: [
        { text: "Naprawa", value: "jobDescription", sortable: false },
        { text: "Pracownik", value: "user", sortable: false },
        { text: "Czas", value: "time", sortable: false },
        { text: "Wartość", value: "value", sortable: false, can: ['view', 'carJob'] },
        { text: "Data", value: "createDate", sortable: false },
        { text: "", value: "action", sortable: false, width: '80px', can: ['add', 'carJob'] },
      ],

      loadingJobs: false,
      loadingUsers: false,
      menuJobTime: false,
      carJobs: [],
      users: [],
      vJob: {
        // userId: null,
        // jobDescription: null,
        // time: null,
        // value: null,
      },
      edit: false,
      dialog: false,
      formTitle: 'Nowa czynność',
    };
  },

  created: function () {
    if(this.entryId>0) {
      this.getCarJobs(this.entryId)
      this.getUsers()
    }
  },

  computed: {
    hideFooter: function() {
      return this.carJobs.length>10?false:true
    },

    cHeadJobs: function() {
      return this.headJobs.filter((column) => {
        if(!column.hasOwnProperty('can')) {
          return true;
        }

        return this.$can(column.can[0], column.can[1])
      })
    },
  },

  watch: {
    entryId: {
      handler: function(val) {
        // console.log('== JobsForm: '+val)
        if(val === null) {
          this.carJobs = [];
          this.edit = false;
          this.vJob = {};
        } else {
          if (val>0) {
            this.getCarJobs(val)
          }
        }
      }
    },

    carJobs: {
      handler: function(val) {
        let sum=0;

        if(this.$can('view', 'carJob')) {
          val.forEach(item=>{
            sum +=item.value;
          });
        }

        let ret = sum>0?'na kwotę: '+sum.toString()+'zł':'';
        this.$emit('input', ret);
      },
      deep: true
    }

  },

  methods: {
    getCarJobs(id) {
      this.loadingJobs = true;
      autoApi
        .get(`/api/v1/entries/`+id+'/carjobs', {
        })
        .then(response => {
          this.carJobs = this.normalizeJobs(response.data.jobs);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loadingJobs = false;
        });
    },

    getUsers() {
      this.loadingUsers = true;
      autoApi.get(`/api/v1/users`, { })
        .then(response => {
          this.users = response.data;

          Object.keys(this.users).forEach(key=>{
            const lastName = this.users[key].lastName===null?'':' '+this.users[key].lastName
            this.users[key] = {
              ...this.users[key],
              name: this.users[key].firstName+lastName
            }
          });
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loadingUsers = false;
        });
    },

    async submitJobs(update=false) {
      this.loadingJobs=true;
      if(await this.$refs.obs0.validate()) {
        if(this.entryId>0) {

          const data = {
            "job": {
              "jobDescription": this.vJob.jobDescription,
              // "userId": this.$can('edit', 'carJob')?this. vJob.user.id:authenticationService.currentUser.id,
              "time": this.vJob.time,
              "value": this.vJob.value,
              "user": this.$can('edit', 'carJob')?this.vJob.user:this.users.find(user => user.id == authenticationService.currentUserValue.id),
            }
          }
          // console.log(data)
          // console.log(this.users.find(user => user.id == authenticationService.currentUserValue.id))
          let submit;
          if(!update) {
            submit = data => autoApi.post(`/api/v1/entries/${this.entryId}/carjobs`, data);
          } else {
            submit = data => autoApi.put(`/api/v1/entries/${this.entryId}/carjobs/${this.vJob.id}`, data);
          }

          submit(data)
          .then((response) => {
            // console.log(response.data.jobs)
            this.carJobs = this.normalizeJobs(response.data.jobs)
            this.vJob = {};
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.edit = false;
            this.loadingJobs=false;
            this.close()
          })
        }
      }
    },

    // Adds name property to jobs
    normalizeJobs(jobs) {
      Object.keys(jobs).forEach(key=>{
        const lastName = jobs[key].user.lastName===null?'':' '+jobs[key].user.lastName
        jobs[key].user = {
          ...jobs[key].user,
          name: jobs[key].user.firstName+lastName
        }
      });
      return jobs
    },

    deleteItem(item) {
      if(confirm('Czy na pewno chcesz usunąć ten wpis?')) {
        autoApi
          .delete(`/api/v1/entries/${this.entryId}/carjobs/${item.id}`)
          .then(response=>{
            // console.log(response.data.jobs)
            this.carJobs = this.normalizeJobs(response.data.jobs);
          })
          .catch(err=>{
            console.log(err);
            authenticationService.authError(err);
          })
      }
    },

    editItem(item) {
      // console.log(item)
      this.dialog=true
      this.edit=true;
      this.formTitle = "Edycja czynności"
      this.vJob = this.carJobs.find(job => job.id == item.id);
    },

    isOwner(item) {
      // console.log(item)
      if(this.$can('edit', 'carJob')) {
        return true;
      }

      if(item.user.id == authenticationService.currentUserValue.id) {
        return true
      }
      return false
    },

    close() {
      this.dialog = false
      // DOM not updated yet
      this.$nextTick(() => {
        // DOM updated
        this.vJob = {}
        this.formTitle = "Nowa czynność"
        this.$refs.obs0.reset()
        // this.editedItem = Object.assign({}, this.defaultItem)
      })
    },

  }
};
</script>

<style>
.info-item {
  white-space:pre-line;
  word-wrap: break-word;
  max-width: 100%;
}
</style>