var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"mx-auto",attrs:{"max-width":"650","flat":"","color":"white"}},[_c('v-spacer'),_c('v-btn',{staticClass:"ml-6",attrs:{"color":"accent","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.newItem()}}},[_c('v-icon',[_vm._v("mdi-plus-thick")])],1)],1),_c('v-dialog',{staticClass:"my-1",attrs:{"max-width":"600","persistent":"","scrollable":""},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"userForm"},[_c('v-form-base',{attrs:{"model":_vm.user,"schema":_vm.userSchema,"col":12},on:{"click":_vm.clickForm}}),_c('v-progress-linear',{attrs:{"color":_vm.score.color,"value":_vm.score.value}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary darken-2"},on:{"click":function($event){_vm.edit=false}}},[_c('span',[_vm._v("Zamknij")])]),_c('v-btn',{attrs:{"text":"","color":"secondary darken-2"},on:{"click":function($event){return _vm.saveUser();}}},[_c('span',[_vm._v("Zapisz")])])],1)],1)],1),_c('v-card',{staticClass:"px-3 mx-auto",attrs:{"max-width":"550","elevation":"0"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"dense":"","items-per-page":20,"footer-props":{
      disableItemsPerPage:true,
      itemsPerPageOptions:[20]
    }},scopedSlots:_vm._u([{key:"item.lock",fn:function(ref){
    var item = ref.item;
return [(item.lock===true)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-account-cancel")]):_vm._e()]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }