<template>
  <div>
    <Entry :entryId="entryId" :show.sync="dialog" :reload.sync="reload" :new-form="newForm" :date="apDate" />
    <v-sheet tile height="54" color="grey lighten-3" class="d-flex">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        outlined
        class="ma-2"
        color="grey darken-2"
        @click="setToday"
      >
        Dzisiaj
      </v-btn>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-toolbar-title class="ma-3">
        {{ title }}
      </v-toolbar-title>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :weekdays="[1,2,3,4,5]"
        type="week"
        :events="events"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="getEvents"
        @click:time = "addAppointment"
        @click:event = "showAppointment"
        v-on:dblclick.native ="dubleClick"
        v-on:click.native ="singleClick"
      >
        <template v-slot:event="{event, start}">
          <v-card v-if="start" :height="25" :color="event.color">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card-text
                  class="py-1 pl-1 pr-0 white--text"
                  v-bind="attrs"
                  v-on="on"
                >
                <v-toolbar flat height=20 :color="event.color" class="white--text">
                  {{event.name }}
                  <v-spacer></v-spacer>
                  <v-btn v-if="$can('add', 'entry')" icon dark fab x-small @click="deleteItem(event)" >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-toolbar>
                </v-card-text>
              </template>
              <span style="white-space: pre-line;">{{ event.description }}</span>
            </v-tooltip>
          </v-card>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import Entry from "@/components/Entry";
import { authenticationService } from '../services/authentication.service'
import { autoApi } from '../services/autoApi'

export default {
  name: "Appointments",
  components: {
    Entry
  },
  data: () => ({
    title: '',
    entryId: null,
    apDate: null,
    dialog: false,
    reload: false,
    newForm: true,
    isDblClick: false,
    weekday: [0, 1, 2, 3, 4, 5, 6],
    focus: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1"
    ],
    names: [
      "LC",
      "LCH",
      "LU",
      "LZ",
      "LC",
      "LC",
      "LCH",
      "WR"
    ]
  }),

  mounted() {
    authenticationService.currentUser.subscribe(this.setLogged)
    // autoApi.logged // ?????????????????????

    this.$refs.calendar.scrollToTime('07:00')
    this.$refs.calendar.checkChange()
  },

  watch: {
    reload(val) { //reload jest potrzebny w przypadku dodania nowego zgłoszenia
      if(val) {
        const start = this.$refs.calendar.lastStart
        const end = this.$refs.calendar.lastEnd
        this.getEvents({start, end});
        this.reload = false;
      }
    },
  },

  methods: {
    getEvents({ start, end }) {
      console.log(start)
      console.log(end)

      let yr = start.year;
      let mn = start.month;

      const mNames = [
        "Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień",
      ];

      this.title = mNames[mn-1] + " " + yr;

      const startDate = new Date(`${start.date}T00:00:00`);
      const endDate = new Date(`${end.date}T23:59:59`);

      Date.prototype.addMinutes = function(m) {
        this.setTime(this.getTime() + (m*60*1000));
        return this;
      }

      this.events = []
      autoApi.get('/api/v1/appointments', {
          params: {
            startDate: startDate,
            endDate: endDate,
          }
        }).then(response => {
          // console.log(response.data)
          response.data.forEach((item) => {
            let itemDate = new Date(item.appointmentDate)

            let sDate = this.formatDate(itemDate)
            let eDate = this.formatDate(itemDate.addMinutes(30))

            let description = item.car.markId?item.car.mark.name:''
            description += item.car.modelId?` ${item.car.model.name}`:' '

            let plate = item.car.plate[0]=='_'?description:item.car.plate

            description += '\n'
            description += item.car.plate[0]!='_'?`Nr rej. ${item.car.plate}\n`:' '
            description += item.car.productionYear?`Rok produkcji: ${item.car.productionYear}\n`:' '
            description += item.car.capacity?`Pojemność: ${item.car.capacity}\n`:' '
            description += item.description?`Opis: ${item.description}\n`:' '

            this.events.push({
              id: item.id,
              name: plate,
              description: description,
              start: sDate,
              end: eDate,
              color: item.label?item.label.color:"blue-grey lighten-2"
            })
          })
        })
    },

    singleClick() {
      this.isDblClick = false
      // console.log(this.isDblClick)
    },
    dubleClick() {
      this.isDblClick = true
      // console.log(this.isDblClick)
    },

    addAppointment(event) {
      // clearTimeout()
      // wait for isDblClick is set
      setTimeout(() => {
        let date;
        if(event.minute<30) {
          date = Date.parse(event.date+' '+event.hour+':00')
        } else {
          date = Date.parse(event.date+' '+event.hour+':30')
        }
        // apDate uzywane takze w showAppointment
        this.apDate = new Date(date)

        if(this.isDblClick && !this.dialog) {
          // console.log(event)
          // console.log(new Date(date))
          this.entryId = 0;
          this.dialog = true
          this.newForm = true;
        }
      }, 400);
    },

    showAppointment(nativeEvent) {
      setTimeout(() => {
        if(this.isDblClick) {
          // console.log(nativeEvent)
          this.entryId = nativeEvent.event.id
          this.dialog = true
          this.newForm = false
        }
      }, 400);
    },

    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() +
            1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
    },
    setToday () {
      this.focus = ''
    },

    deleteItem(item) {
      if(confirm('Czy na pewno chcesz usunąć ten termin?')) {
        console.log(item.id)
        autoApi
          .delete(`/api/v1/entries/${item.id}`)
          .then(()=>{
            this.reload=true;
          })
          .catch(err=>{
            console.log(err);
            authenticationService.authError(err);
          })
      }
    }

  }
};
</script>

<style>
</style>